import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { Mobile } from "../../responsive";

export const CardItemBox = styled(Box)`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const CardItemDesc = styled(Box)`
  display: flex;
  flex-direction: column;
  gap:5px;
`;

export const ImageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightlowgray};
  border-radius: 10px;
  width: 150px;
  height: 100px;
  overflow: hidden;
  position: relative;
  
`;

export const Image = styled.img`

  &.widget--image{
  object-fit: contain;
  max-width: 97%;
  max-height: 95%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  }
`;

export const CardItemTitle = styled(Typography)`
  font-weight: bold; /* Optional: make title stand out */
  font-weight: 700;
  line-height: 1;
  color:  ${({ theme }) => theme.colors.black};
  letter-spacing: 1px;
`;



export const CardItemPrice = styled(Box)`
 
  color: ${({ theme }) => theme.colors.primary}; /* Optional: use theme color for price */
`;

export const ProductHeading = styled.h3`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding-bottom: 0.6rem;
  

  &::after {
    content: "";
    width: 80px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  ${Mobile({ fontSize: "1rem" })}
`;
