import styled from "styled-components";
import { Link } from '../../globalStyle';
import { List, ListItem } from '@mui/material';

export const StyledList = styled(List)`
  padding: 0;
  
`;

export const StyledListItem = styled(ListItem)`
 padding: 0;
`

export const StyledLink = styled(Link)`
  padding-bottom: 10px;
`;

