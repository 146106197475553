import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Grid,
  FormControl,
  Typography,
  Box,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from 'styled-components';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch } from 'react-redux';
import { submitQuotation } from '../../reducers/quotationReducer';
import { sanitizeInput } from '../../helpers/globalHelper';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { showSnackbar } from '../../reducers/snackbarSlice';
import AlertMessage from '../alert-message/alert-message.component';

// Styled-components for custom styles
const FormWrapper = styled.div`
  padding: 20px;
  margin: 0 auto;
  max-width: 600px;
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ProductImage = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin-right: 10px;
`;

const RemoveImageButton = styled(Button)`
  display: flex;
  align-items: center;
`;
// Yup schema for form validation
const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  phone: Yup.string().required('Phone is required').test(
    'is-valid-phone',
    'Invalid phone number format',
    value => {
      try {
        const phoneNumber = parsePhoneNumber(value);
        return phoneNumber ? isValidPhoneNumber(phoneNumber.number, phoneNumber.country) : false;
      } catch (error) {
        console.error('Phone number validation error:', error);
        return false;
      }
    }
  ),
  customization: Yup.string().required('Customization requirements are required'),
});

const QuotationForm = () => {
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [phonePlaceholder, setPhonePlaceholder] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      customization: '',
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      let formattedPhone = values.phone;

      try {
        const phoneNumber = parsePhoneNumber(values.phone);
        if (phoneNumber) {
          formattedPhone = phoneNumber.formatInternational();
        }
      } catch (error) {
        console.error('Error formatting phone number:', error);
      }

      const formData = new FormData();
      formData.append('name', sanitizeInput(values.name));
      formData.append('email', sanitizeInput(values.email));
      formData.append('phone', sanitizeInput(formattedPhone));
      formData.append('customization', sanitizeInput(values.customization));

      if (uploadedFile) {
        formData.append('image', uploadedFile);
      }

      dispatch(submitQuotation(formData))
        .then(() => {
          dispatch(showSnackbar({ message: 'Quotation Request submitted successfully!', severity: 'success' }));
          setImagePreview(null);
          setUploadedFile(null);
          resetForm();
        })
        .catch(() => {
          dispatch(showSnackbar({ message: 'Error submitting Quotation Request. Please try again.', severity: 'error' }));
        });
    },
  });

  // Handle image upload and validation
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png'];
      if (!validTypes.includes(file.type)) {
        alert('Only JPG and PNG formats are allowed.');
        return;
      }

      const maxSize = 2 * 1024 * 1024; // 2MB
      if (file.size > maxSize) {
        alert('File size exceeds 2MB.');
        return;
      }

      setImagePreview(URL.createObjectURL(file));
      setUploadedFile(file);
    }
  };

  // Remove image preview
  const removeImage = () => {
    setImagePreview(null);
    setUploadedFile(null);
  };

  // Handle phone number format and placeholder
  const handlePhoneChange = (event) => {
    const { value } = event.target;
    formik.handleChange(event);

    let formattedNumber = '';
    let placeholder = 'Enter phone number';

    try {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber) {
        if (isValidPhoneNumber(phoneNumber.number, phoneNumber.country)) {
          formattedNumber = phoneNumber.formatInternational();
          placeholder = formattedNumber;
        }
      }
    } catch (error) {
      console.error('Phone number parsing error:', error);
      placeholder = 'Enter valid phone number';
    }

    setPhonePlaceholder(placeholder);
  };

  return (
    <FormWrapper>
      <Typography variant="h4" gutterBottom>Quotation Form</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Phone"
                name="phone"
                value={formik.values.phone}
                onChange={(e) => {
                  handlePhoneChange(e);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                placeholder="e.g., +1 202-555-0143"

                fullWidth
              />
              <Typography  margin={1} variant='body2'> {phonePlaceholder}</Typography>
            </FormControl>
            <Box sx={{ margin: '10px 0' }}>
              <AlertMessage message='e.g., +1 202-555-0143' />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Customization Requirements"
                name="customization"
                multiline
                rows={4}
                value={formik.values.customization}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.customization && Boolean(formik.errors.customization)}
                helperText={formik.touched.customization && formik.errors.customization}
                fullWidth
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              component="label"
              startIcon={<CloudUploadIcon />}
              fullWidth
            >
              Upload Image
              <input
                type="file"
                hidden
                onChange={handleImageUpload}
              />
            </Button>

            {imagePreview && (
              <ImagePreviewContainer>
                <ProductImage src={imagePreview} alt="Preview" />
                <RemoveImageButton
                  variant="outlined"
                  onClick={removeImage}
                  startIcon={<CancelIcon />}
                >
                  Remove
                </RemoveImageButton>
              </ImagePreviewContainer>
            )}

            <Box sx={{ margin: '10px 0' }}>
              <AlertMessage message='Image allowed jpg,png. size of image should be less than 2MB' />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" fullWidth>
              Submit Quotation
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormWrapper>
  );
};

export default QuotationForm;
