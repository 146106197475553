import React from 'react';
import { Button as MuiButton } from '@mui/material';
import styled from 'styled-components';

const CustomButton = styled(MuiButton)`
  && {
    text-transform: none;
    padding: 6px 12px;
    line-height: 1.5;
    color: ${(props) => props.color};
    background-color: ${props => props.bgcolor};
    border-color: ${props => props.borderColor || 'none'};
  }
`;

export const ButtonMui = ({ children, ...props }) => (
  <CustomButton  {...props}>
    {children}
  </CustomButton>
);