import React from 'react'
import {
  CardBox,
  CardTitle,
} from './product-widget-card.style'
import ProductWidgetCardItem from '../product-widget-card-item/product-widget-card-item'
import { capitalizeFirstLetter } from '../../helpers/globalHelper'
import { Box, Button, Typography } from '@mui/material'
import useLoadMore from '../../helpers/useLoadMore'
import LoginRequired from '../sign-in-required/login-required.component'

const ProductWidgetCard = ({
  title = "Recent Products",
  products = [], userId = null,
  userRequired = false,
  recentTrack = false
}) => {

  const {
    visibleCount,
    handleLoadMore,
    handleShowLess
  } = useLoadMore(5, 5);

 

  if (userId === null && userRequired) {
    return <CardBox>
      <CardTitle variant='h6'>{capitalizeFirstLetter(title)}</CardTitle>
      <LoginRequired title="Login to track recent products" />
    </CardBox>
  } else if (recentTrack) {
    return <CardBox>
      <CardTitle variant='h6'>{capitalizeFirstLetter(title)}</CardTitle>
      <Typography variant='body1'>No recent product yet explored! </Typography>
    </CardBox>
  }

  return (
    <CardBox>
      <CardTitle variant='h6'>{capitalizeFirstLetter(title)}</CardTitle>
      {products?.slice(0, visibleCount).map((product) => <ProductWidgetCardItem
        key={product.id}
        product={product}
      />
      )
      }
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, margin: '20px 0' }}>
        {visibleCount < products.length && (
          <Button variant="contained" onClick={() => handleLoadMore(products.length)}>
            Load More
          </Button>
        )}
        {visibleCount > 5 && (
          <Button variant="outlined" onClick={() => handleShowLess(5, products.length)}>
            Show Less
          </Button>
        )}
      </Box>
    </CardBox>
  )
}

export default ProductWidgetCard