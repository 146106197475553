import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, Alert, Button } from '@mui/material';
import { hideSnackbar } from '../../reducers/snackbarSlice';

const CustomizedSnackbar = () => {
  const dispatch = useDispatch();
  const snackbarState = useSelector((state) => state.snackbar);

  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  return (
    <Snackbar
      open={snackbarState.open}
      autoHideDuration={3000}
      onClose={handleClose}
      action={
        <Button color="inherit" onClick={handleClose}>
          Close
        </Button>
      }
    >
      <Alert onClose={handleClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomizedSnackbar;
