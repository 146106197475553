// src/components/search-query-form.component.jsx
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Skeleton } from '@mui/material';
import debounce from 'lodash.debounce';
import { clearSearchResults, searchProducts } from '../../reducers/searchSlice';
import {
  ProductImage,
  SearchBox,
  SearchButton,
  SearchWrapper,
  SkeletonWrapper,
  StyledAutocomplete,
  StyledBox,
  StyledTextField,
  SuggestionItem
} from './search-query-form.style';
import { IconSearch, Link } from '../../globalStyle';
import { ProductTitle } from '../../helpers/globalHelper';


const SearchQueryForm = () => {
  const dispatch = useDispatch();
  const { suggestions, status } = useSelector((state) => state.search);

  const formik = useFormik({
    initialValues: { query: '' },
    validationSchema: Yup.object({
      query: Yup.string().required('Search query is required'),
    }),
    onSubmit: (values) => {
      dispatch(searchProducts(values.query));
    },
    onReset: () => {
      dispatch(clearSearchResults());
    },
  });


  // Handle input change and trigger debounced search
  const handleInputChange = (e) => {
    const query = e.target.value;
    formik.setFieldValue('query', query);

    // Debounce the search to avoid making too many API calls while typing
    const debouncedSearch = debounce((query) => {
      dispatch(searchProducts(query));
    }, 300);

    if (query.length > 0) {
      debouncedSearch(query);
    } else {
      dispatch(clearSearchResults()); // Clear suggestions if input is empty
    }
  };



  return (
    <SearchWrapper>
      <form onSubmit={formik.handleSubmit}>
        <StyledAutocomplete
          freeSolo
          options={suggestions}
          getOptionLabel={(option) => (option?.name ? ProductTitle(option?.name) : 'Please, wait or search another product')}
          renderOption={(props, option) => {
            // Show skeleton while loading
            if (status === 'loading') {
              return (
                <SkeletonWrapper {...props}>
                  <Skeleton variant="text" width={100} height={150} style={{ marginRight: '10px' }} />
                  <Skeleton variant="text" height={150} width="100%" />
                </SkeletonWrapper>
              );
            }
            // Show actual product once loading is complete
            return (
              <StyledBox {...props}>
                <Link to={`/single/product/${option.slug}/${option.id}`}>
                  <SuggestionItem>
                    <ProductImage src={option?.thumbnail} />
                    <span>{ProductTitle(option?.name)}</span>
                  </SuggestionItem>
                </Link>
              </StyledBox>
            );
          }}
          renderInput={(params) => (
            <SearchBox>
              <StyledTextField
                {...params}
                label="Search for products"
                variant="outlined"
                onChange={handleInputChange}
                value={formik.values.query}
                error={formik.touched.query && Boolean(formik.errors.query)}
                helperText={formik.touched.query && formik.errors.query}
              />
              <SearchButton
                type='submit'
                disabled={status === 'loading'}
                color="primary"
              >
                <IconSearch />
                Search
              </SearchButton>
            </SearchBox>
          )}
        />
      </form>
    </SearchWrapper>
  );
};

export default SearchQueryForm;
