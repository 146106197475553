import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React from 'react'
import UserAuth from './user-auth.component'

const UserAuthModal = ({ usingModal, isDialogOpen, closeDialog }) => {
  return (
    <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth maxwidth="lg">
      <DialogContent>
        <UserAuth usingModal={usingModal} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant='outlined' onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserAuthModal