import React, {  useRef } from 'react'
import { Container } from '../../globalStyle'
import { WidgetGridBox } from './product-widgets.style'
import { Grid } from '@mui/material'
import ProductWidgetCard from '../product-widget-card/product-widget-card.component'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRecentViews } from '../../reducers/recentViewSlice'
import { fetchPopularViews } from '../../reducers/popularViewSlice'
import { fetchNewArrivals } from '../../reducers/newArrivalsSlice'
import useLazyLoadAPI from '../../helpers/useLazyLoadAPI'

const ProductWidgets = ({ type }) => {
  const dispatch = useDispatch();
  const widgetsRef = useRef();
  const { recentViews, error } = useSelector(state => state.recentViews);
  const { popularViews, } = useSelector(state => state.popularViews);
  const { newArrivals, } = useSelector(state => state.newArrivals);
  const { token, user } = useSelector((state) => state.auth);
  const userId = user && token ? user.id : null;

  useLazyLoadAPI(widgetsRef, () => dispatch(fetchRecentViews({ type, userId })));
  useLazyLoadAPI(widgetsRef, () => dispatch(fetchPopularViews(type)));
  useLazyLoadAPI(widgetsRef, () => dispatch(fetchNewArrivals()));

  // useEffect(() => {
  //   dispatch(fetchRecentViews({ type, userId }));
  // }, [dispatch, type, userId]);

  // useEffect(() => {
  //   dispatch(fetchPopularViews(type));
  // }, [dispatch, type]);

  // useEffect(() => {
  //   dispatch(fetchNewArrivals());
  // }, [dispatch]);

  if (error) {
    return [];
  }
  return (
    <Container ref={widgetsRef} className='fade-in'>
      <WidgetGridBox container spacing={2}>
        {newArrivals.length !== 0 &&
          <Grid item xs={12} sm={6} lg={4}>
            <ProductWidgetCard
              title={'New Arrivals'}
              products={newArrivals}
            />
          </Grid>

        }

        {
          <Grid item xs={12} sm={6} lg={4}>
            <ProductWidgetCard
              title={'recently viewed'}
              products={recentViews}
              recentTrack={recentViews.length === 0}
              userId={userId}
              userRequired={true}
            />
          </Grid>

        }
        {popularViews.length !== 0 &&
          <Grid item xs={12} sm={6} lg={4}>
            <ProductWidgetCard
              title={'Popular viewed'}
              products={popularViews}
              userRequired={false}
            />
          </Grid>

        }

      </WidgetGridBox>
    </Container>
  )
}

export default ProductWidgets