import React from 'react'
import {
  FacebookIcon,
  FlexContainer,
  InstagramIcon,
  Link,
  PintestIcon,
  WhatsAppIcon
} from '../../globalStyle'
import { IconBg } from './social-links.style'

const SocialLinks = ({justify}) => {
  return (
    <FlexContainer $justify={justify} style={{ gap: '15px' }}>
      <Link
        style={{ color: 'white' }}
        to="https://wa.me/923187637515"
        target='_blank'>
        <IconBg>
          <WhatsAppIcon />
        </IconBg>
      </Link>
      <Link
        style={{ color: 'white' }}
        to="https://www.facebook.com/newyousports/"
        target='_blank'>
        <IconBg>
          <FacebookIcon />
        </IconBg>
      </Link>
      <Link
        style={{ color: 'white' }}
        to="https://www.instagram.com/newyousports/"
        target='_blank'>
        <IconBg>
          <InstagramIcon />
        </IconBg>

      </Link>
      <Link
        style={{ color: 'white' }}
        to="https://www.pinterest.com/newyousports/"
        target='_blank'>
        <IconBg>
          <PintestIcon />
        </IconBg>
      </Link>
    </FlexContainer>
  )
}

export default SocialLinks