import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";
import { LOCAL_STORAGE_COUPON_CODE, LOCAL_STORAGE_COUPON_DISCOUNT, LOCAL_STORAGE_COUPON_TYPE } from "../context/GlobalStates";

// Async thunk for validating the coupon
export const validateCoupon = createAsyncThunk(
  'coupon/validateCoupon',
  async (code, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiValidateCouponCode(code));
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown coupon error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

// Coupon slice
const couponSlice = createSlice({
  name: 'coupon',
  initialState: {
    discount: localStorage.getItem(LOCAL_STORAGE_COUPON_DISCOUNT) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_COUPON_DISCOUNT)) : 0,
    discountType: localStorage.getItem(LOCAL_STORAGE_COUPON_TYPE) || '',
    coupon: localStorage.getItem(LOCAL_STORAGE_COUPON_CODE) || null,
    status: 'idle',
    error: null,
  },
  reducers: {
  
    clearCoupon: (state) => {
      state.discount = 0;
      state.discountType = '';
      state.coupon = null;
      localStorage.removeItem(LOCAL_STORAGE_COUPON_DISCOUNT);
      localStorage.removeItem(LOCAL_STORAGE_COUPON_TYPE);
      localStorage.removeItem(LOCAL_STORAGE_COUPON_CODE);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateCoupon.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(validateCoupon.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { code, discount_value, discount_type } = action.payload.coupon;
        state.coupon = code;
        state.discount = discount_value;
        state.discountType = discount_type;
        localStorage.setItem(LOCAL_STORAGE_COUPON_DISCOUNT, JSON.stringify(discount_value));
        localStorage.setItem(LOCAL_STORAGE_COUPON_TYPE, discount_type);
        localStorage.setItem(LOCAL_STORAGE_COUPON_CODE, code);
      })
      .addCase(validateCoupon.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const {  clearCoupon } = couponSlice.actions;
export default couponSlice.reducer;
