import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GlobalStyle, styledTheme } from "./globalStyle";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material";
import Layout from "./layout/layout";
import useAxiosAuthHeader from "./hooks/useAxiosAuthHeader";
import useGoogleAnalytics from "./hooks/useGoogleAnalytics";
import PrivateRoute from "./component/private-route/private-route.component";
import ScrollTop from "./component/scroll/ScrollTop";
import { lazy, Suspense } from "react";
import FullPageSpinner from "./component/spinner/spinner.component";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage ";
import RefundReturnPolicyPage from "./pages/RefundReturnPolicyPage";
import useOneSignal from "./hooks/useOneSignal";
import Page404 from "./pages/Page404";
import EmailVerificationSuccessPage from "./pages/EmailVerificationSuccessPage";

// Lazy loaded components
const HomePage = lazy(() => import('./pages/HomePage'));
const UserAuthPage = lazy(() => import('./pages/UserAuthPage'));
const ProductPage = lazy(() => import('./pages/ProductPage'));
const CartPage = lazy(() => import('./pages/CartPage'));
const CheckoutPage = lazy(() => import('./pages/CheckoutPage'));
const ThankYouPage = lazy(() => import('./pages/ThankYouPage'));
const UserDashboardPage = lazy(() => import('./pages/UserDashboardPage'));
const ForgotPassword = lazy(() => import('./component/user-authentication/user-forget-password'));
const ResetPassword = lazy(() => import('./component/user-authentication/user-reset-password'));
const MockupPage = lazy(() => import('./pages/MockupPage'));
const ShopPage = lazy(() => import('./pages/ShopPage'));

const MuiTheme = createTheme({

  palette: {
    primary: {
      main: '#D04848',
    },
    secondary: {
      main: '#6895D2',
    },
  },


});


function App() {


  return (
    <MuiThemeProvider theme={MuiTheme}>
      <StyledThemeProvider theme={styledTheme}>
        <Router>
          <GlobalStyle />
          <Suspense fallback={<FullPageSpinner />}>
            <InnerApp />
          </Suspense>
          <ScrollTop />
        </Router>
      </StyledThemeProvider>
    </MuiThemeProvider>
  );
}


const InnerApp = () => {
  useGoogleAnalytics();
  useAxiosAuthHeader();
  // useTokenExpiration(); // Global token expiration check
  const oneSignalAppId = "0568f0a1-0ff4-40c8-bbac-ad15f3bb2788"; // Your OneSignal app ID
  useOneSignal(oneSignalAppId);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/user/auth" element={<UserAuthPage />} />
        <Route path="/single/product/:slug/:productId" element={<ProductPage />} />
        <Route path="/single/mockup/:slug/:productId" element={<MockupPage />} />
        <Route path="/shop/:categoryId?" element={<ShopPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/password/forgot" element={<ForgotPassword />} />
        <Route path="/password/reset/:token" element={<ResetPassword />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/refund-return" element={<RefundReturnPolicyPage />} />

        <Route element={<PrivateRoute />}>
          <Route path="/user/:section?" element={<UserDashboardPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/verify-success" element={<EmailVerificationSuccessPage />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}

export default App;
