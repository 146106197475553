import React from 'react'
import { Alert } from '@mui/material'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
const AlertMessage = ({ message = "No Message", type = "info", icon }) => {
  const defaultIcons = {
    info: <InfoIcon />,
    success: <CheckCircleOutlineIcon />,
    warning: <WarningRoundedIcon />,
    error: <WarningRoundedIcon />,
  };

  const alertIcon = icon || defaultIcons[type];
  return (
    <Alert severity={type} icon={React.cloneElement(alertIcon, { fontSize: 'inherit' })}>
      {message}
    </Alert>
  )
}

export default AlertMessage