import { Box } from '@mui/material';
import styled from 'styled-components';

export const LoginBox = styled(Box)`
  margin: 10px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

`
