import styled from "styled-components";
import { MinTablet600 } from "../../responsive";
import { Container } from "../../globalStyle";
import { Box } from "@mui/material";

export const NavbarSection = styled.section`
  
`;

export const NavbarContainer = styled(Container)`
  padding: 10px 0;
`;

export const HeaderBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${MinTablet600({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0'
})}

`;

// Navigation container
export const Navigation = styled(Box)`
  ul {
    display: block;
    list-style: none;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    ${MinTablet600({ display: 'flex' })}
    li {
      margin-right: 10px;
      margin-top: 15px;
      ${MinTablet600({ marginTop: '0', })}
      &:last-child {
        margin-right: 0;
      }
      
      a {
        text-decoration: none;
        display: block;
        color: #333;
        font-weight: bold;
        padding: 5px 10px;
        border-radius: 5px;
        transition: background-color 0.3s ease, color 0.3s ease;
        &.active {
          background-color: ${({ theme }) => theme.colors.primary}; /* Active color */
          color: #ffffff; /* Active text color */
        }

        &:hover {
          background-color: ${({ theme }) => theme.colors.primary};
          color: #ffffff;
        }
      }
    }
  }
`;

// Container for icon buttons
export const IconBody = styled(Box)`
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 20px;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
    span {
      margin-left: 5px;
      font-size: 14px;
    }
  }
`;

// Container for individual icons
export const IconsContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary}
`;

