// src/reducers/emailVerificationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import AppURL from '../api/AppURL';

// Thunk to send verification email
export const sendVerificationEmail = createAsyncThunk(
  'emailVerification/sendVerificationEmail',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiEmailVerification());
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk to check if email is verified
export const checkEmailVerified = createAsyncThunk(
  'emailVerification/checkEmailVerified',
  async ({ id, hash }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/verify-email/${id}/${hash}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const emailVerificationSlice = createSlice({
  name: 'emailVerification',
  initialState: {
    loading: false,
    verified: false,
    success: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle sendVerificationEmail
    builder.addCase(sendVerificationEmail.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    })
      .addCase(sendVerificationEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(sendVerificationEmail.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });

    // Handle checkEmailVerified
    builder.addCase(checkEmailVerified.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(checkEmailVerified.fulfilled, (state, action) => {
      state.loading = false;
      state.verified = true;
    });
    builder.addCase(checkEmailVerified.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default emailVerificationSlice.reducer;
