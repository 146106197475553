import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material';
import { resetState, subscribeUser } from '../../reducers/subscriberSlice';
import CustomizedSnackbar from '../customized-snackbar/customized-snackbar.component';
import { sanitizeInput } from '../../helpers/globalHelper';
import HeadingTitle from '../heading-title/heading-title.component';
import { StyledBox, StyledForm } from './subscriber-form.style';


const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

const SubscriberForm = () => {
  const dispatch = useDispatch();
  const { status,  error } = useSelector((state) => state.subscriber);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    // Reset the state when the component unmounts
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (status === 'succeeded') {
      setSnackbarMessage('Subscription successful!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } else if (status === 'failed') {
      setSnackbarMessage(error || 'Subscription failed. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [status, error]);

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const sanitizedValues = {
      name: sanitizeInput(values.name),
      email: sanitizeInput(values.email),
    };


    dispatch(subscribeUser(sanitizedValues)).then(() => {
      resetForm();
      setSubmitting(false);
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <StyledBox>
      <HeadingTitle title="Join Our Exclusive Community!" />
      <Typography
        variant="body2"
        align="center"
        sx={{ color: '#777', fontStyle: 'italic', margin: '10px 0' }}
      >
        Sign up now and get news our latest promotions, exclusive offers, and personalized mockup designs, No spam, just quality updates!
      </Typography>

      <Formik
        initialValues={{ name: '', email: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <StyledForm>
            <Field
              as={TextField}
              name="name"
              label="Name"
              fullWidth
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              variant="outlined"
            />
            <Field
              as={TextField}
              name="email"
              label="Email"
              fullWidth
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              variant="outlined"
            />
            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting || status === 'loading'}>
              {isSubmitting || status === 'loading' ? <CircularProgress size={24} /> : 'Subscribe'}
            </Button>
          </StyledForm>
        )}
      </Formik>

      {/* Snackbar for success/failure notifications */}
      <CustomizedSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
    </StyledBox>
  );
};

export default SubscriberForm;
