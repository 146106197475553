import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";


export const fetchSizes = createAsyncThunk(
  'sizes/fetchSizes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetSizes());
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred while fetching sizing';
      return rejectWithValue(errorMessage);
    }
  }
);

const sizesSlice = createSlice({
  name: 'sizes',
  initialState: {
    sizes: [],
    isLoading: false,
    error: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSizes.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSizes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sizes = action.payload;
      })
      .addCase(fetchSizes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;

      });
  }
});

export default sizesSlice.reducer;