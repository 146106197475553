import React, { useEffect } from 'react'
import PrivacyPolicy from '../component/privacy-policy/privacy-policy.component'
import { Box } from '@mui/material';

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Box sx={{ marginBottom: '100px' }}>
      <PrivacyPolicy />
    </Box >
  )
}

export default PrivacyPolicyPage 