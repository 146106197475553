import styled from "styled-components";

export const IconBg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background:  ${({ theme }) => theme.colors.primary};
    color: white;
    cursor: pointer;
  `;