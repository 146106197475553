import ReactGA from 'react-ga';

export const googleTrackingId = 'G-BN57SNT9JQ';

export const initGA = (trackingId) => {
  ReactGA.initialize(trackingId);
}

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export const logEvent = (category = '', action = '') => {
  if (category && action) {
    ReactGA.event({ category, action });
  }
}

export const logExeption = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
}