import { Box } from "@mui/material";
import { Form } from "formik";
import styled from "styled-components";

// Styled component for form
export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
  margin: auto;
`;

export const StyledBox = styled(Box)`
  background-color: ${({theme})=> theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 20px;
  margin-top: -70px;
`