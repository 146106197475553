import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import AppURL from '../api/AppURL';

// Async thunk to fetch recent views
export const fetchCategories = createAsyncThunk(
  'fetchCategory/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiFetchCategoreis());
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error.response.data || 'Unknown error occurred while fetching categories');
    }
  }
);


// Function to invalidate and refetch categories
export const invalidateCategories = () => (dispatch) => {
  // Clear the categories first
  dispatch(clearCategories());

  // Refetch the categories
  dispatch(fetchCategories());
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
    status: 'idle',
    error: null
  },
  reducers: {
    clearCategories: (state) => {
      state.categories = []; // Clear categories
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

// Export the clearCategories action to be used in invalidateCategories
export const { clearCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;
