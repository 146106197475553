import { Drawer } from "@mui/material";
import { useGlobalState } from "../../context/GlobalStates";
import { CloseIcon } from "../../globalStyle";
import Navbar from "../navbar/navbar.component";
import {
  CopyRightContainer,
  FlexContainer,
  Head,
  HeaderBody,
  HeaderFooter,
  HeaderMobileContainer,
  HeaderMobileSection,
  SocialContainer
} from "./header-mobile.style";
import SocialLinks from "../social-links/social-links.component";
import CopyRightNewYou from "../copyright-component/copyright.component";
import ContactInfo from "../contact-info/contact-info.component";
import NewYouLogo from "../newyou-logo/newyou-logo.component";



const HeaderMobile = () => {
  const { setIsMenuOpen, isMenuOpen, } = useGlobalState();
  return (
    <Drawer anchor="left"
      open={isMenuOpen}
      onClose={setIsMenuOpen}
    >
      <HeaderMobileSection>
        <HeaderMobileContainer>
          <Head>
            <NewYouLogo
              nys
              rounded
              altText="NewYouSports Logo" />
            <CloseIcon onClick={() => {
              setIsMenuOpen((boolen) => !boolen);
            }} />
          </Head>
          <HeaderBody>
            <Navbar />
          </HeaderBody>
          <HeaderFooter>
            <ContactInfo />
            <SocialContainer>
              <b>Follow Us</b>
              <FlexContainer>
                <SocialLinks />
              </FlexContainer>
              <CopyRightContainer>
                <CopyRightNewYou />
              </CopyRightContainer>
            </SocialContainer>
          </HeaderFooter>
        </HeaderMobileContainer>
      </HeaderMobileSection>
    </Drawer>
  );
};

export default HeaderMobile;
