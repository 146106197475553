// src/reducers/searchSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import AppURL from '../api/AppURL';

// Async thunk for searching products
export const searchProducts = createAsyncThunk(
  'search/searchProducts',
  async (query, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiSearchQuery(), { params: { query }});
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred while fetching Products';
      return rejectWithValue(errorMessage);
    }
  });

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    suggestions: [],
    products: [],
    status: 'idle',
    loading: false,
    error: null,
  },
  reducers: {
    clearSearchResults: (state) => {
      state.suggestions = [];
      state.products = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchProducts.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(searchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        state.suggestions = action.payload.suggestions;
        state.products = action.payload.products;
      })
      .addCase(searchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearSearchResults } = searchSlice.actions;
export default searchSlice.reducer;
