import { Autocomplete, Box, Button, TextField } from "@mui/material";
import styled from "styled-components";

export const SearchWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  
  .MuiAutocomplete-inputRoot {
    border-radius: 20px;
    padding: 5px;
  }

  // Styling the dropdown container
  .MuiAutocomplete-paper {
    background-color: #f9f9f9;
    border: 5px solid;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    overflow: hidden;
  }

  // Styling each individual suggestion
  .MuiAutocomplete-option {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    background-color: white;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f0f4ff; // Hover effect for better visibility
    }
  }

  .MuiAutocomplete-noOptions {
    color: ${({ theme }) => theme.colors.primary};
    padding: 10px;
  }
`;

export const SuggestionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`;

export const ProductImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
`;

export const StyledBox = styled(Box)`
  border: none;
  border-radius: 8px;
  width: 100%;
  padding: 8px;

`;

export const SkeletonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const SearchBox = styled(Box)`
  display: flex;
  align-items: center;  
`;


export const StyledTextField = styled(TextField)`
  flex: 1;
  
  .MuiOutlinedInput-root {
    & fieldset {
      border: none; /* Removes default border */
    }
    
    &:hover fieldset {
      border: none; /* Removes border on hover */
    }

    &.Mui-focused fieldset {
      border: none; /* Removes border when focused */
    }
  }

  .MuiInputBase-input {
    padding: 12px;
  }

  .MuiInputLabel-root {
    font-size: 1rem;
    transition: all 0.3s ease;
    padding-left: 15px; /* Adjust label position */
  }

  .MuiInputLabel-shrink {
    transform: translate(0, -6px) scale(0.8); /* Moves the label up and shrinks it */
    background-color: white;
    padding: 0 4px; /* Adds padding behind the label */
  }

  .Mui-focused .MuiInputLabel-shrink {
    color: ${({ theme }) => theme.colors.primary}; /* Change label color on focus */
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.primary}; /* Change border color on focus */
  }
`;

export const SearchButton = styled(Button)`
  background-color: #1976d2;
  color: white;
  margin-left: 8px;
  &:hover {
    background-color: #1565c0;
  }
  svg {
    margin-right: 4px;
  }
`;