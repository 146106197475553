import { Box, Grid } from '@mui/material'
import React from 'react'
import { Container } from '../../globalStyle'
import { FooterSection, StyledFooterBottom } from './footer.style'
import SubscriberForm from '../subscriber-form/subscriber-form.component'
import ContactInfo from '../contact-info/contact-info.component'
import SocialLinks from '../social-links/social-links.component'
import HeadingTitle from '../heading-title/heading-title.component'
import CopyRightNewYou from '../copyright-component/copyright.component'
import FooterNavigation from '../footer-navigation/footer-navigation.component'

const Footer = () => {
  return (
    <FooterSection>
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} md={5}>
            <Box>
              <SubscriberForm />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <HeadingTitle title="Contact us" />
                <Box sx={{ margin: '-40px -20px' }}>
                  <ContactInfo border="none" margin="0" />
                </Box>

                <SocialLinks justify="start" />
              </Grid>
              <Grid item xs={12} md={6}>
                <HeadingTitle title="Site Link" />
                <Box>
                  <FooterNavigation />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <StyledFooterBottom>
          <CopyRightNewYou />
        </StyledFooterBottom>
      </Container>
    </FooterSection>


  )
}

export default Footer