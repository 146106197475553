import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AppURL from "../api/AppURL";
import axios from "axios";


export const fetchWallet = createAsyncThunk(
  'wallet/fetchWallet',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiFetchWallet(userId));
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown Wallet error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    balance: 0,
    transactions: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    resetWallet: (state) => {
      state.balance = 0;
      state.transactions = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWallet.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchWallet.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.balance = parseFloat(action.payload.balance);
       
      })
      .addCase(fetchWallet.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetWallet } = walletSlice.actions;

export default walletSlice.reducer;

