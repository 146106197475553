import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchCategories, invalidateCategories } from '../reducers/categoriesSlice';  // Adjust the path accordingly

const useFetchCategories = () => {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.categories); // Access categories status

  useEffect(() => {
    if (status === 'idle') {
      // Only fetch if status is 'idle'
      dispatch(fetchCategories());
    }
  }, [dispatch, status]);

  // Function to manually clear and refetch categories
  const refreshCategories = () => {
    dispatch(invalidateCategories());
  };

  return {
    refreshCategories,
  };
};

export default useFetchCategories;
