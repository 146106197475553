import React from 'react'
import { FlexContainer, InfoContainer } from './contact-info.style'
import { DraftIcon, Link, LocationIcon, TelephoneIcon } from '../../globalStyle'

const ContactInfo = ({border}) => {
  return (
    <InfoContainer $border={border}>
      <FlexContainer>
        <LocationIcon />
        <span style={{ fontSize: "14px" }}>Kashmir Road Sialkot, Pakistan</span>
      </FlexContainer>
      <FlexContainer>
        <DraftIcon primary="true" size="true" />
        <Link to="mailto:info@newyousports.pk">info@newyousports.pk</Link>
      </FlexContainer>
      <FlexContainer>
        <TelephoneIcon />
        <Link to="tel:92-318-763-7515">+92 318 7637515</Link>
      </FlexContainer>
    </InfoContainer>
  )
}

export default ContactInfo