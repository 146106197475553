import React, { Fragment, useEffect } from 'react'
import VisitorInfoComponent from '../component/visitorInfo-Component/visitorInfo-Component'
import Header from '../component/header/header.components'
import { Outlet } from 'react-router-dom'
import ProductWidgets from '../component/product-widgets/product-widgets.component'
import useFetchCategories from '../helpers/useFetchCategories'
import Footer from '../component/footer-component/footer.component'
import EmailSendNotification from '../component/email-send-notification/email-send-notification'

const Layout = () => {

  useFetchCategories(); // Use the custom hook to fetch categories
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Fragment>
      <VisitorInfoComponent />
      <Header />
      <main>
        <EmailSendNotification />
        <Outlet />
      </main>
      <ProductWidgets type="product" />
      <Footer />
    </Fragment >
  )
}

export default Layout