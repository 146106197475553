import { Typography } from '@mui/material'
import { FlexContainer } from '../../globalStyle'

const CopyRightNewYou = () => {
  return (
    <FlexContainer>
      <Typography variant="body2">
        Copyright 2023 - {new Date().getFullYear()}
        © New You Sports. All rights reserved. Developed by AlmasThemes.
      </Typography>

    </FlexContainer>
  )
}

export default CopyRightNewYou