import { Box, Divider } from "@mui/material";
import styled from "styled-components";


export const FooterSection = styled.section`
  background-color: ${({theme}) => theme.colors.lightlowgray};
  margin-top: 150px;

`

export const StyledFooterBottom = styled(Box)`
  color: ${({theme}) => theme.colors.primary};
  margin-top: 30px;
  border: 1px solid ${({theme}) => theme.colors.primary};
  border-radius: 15px;
  padding: 5px;
  
`;
export const StyledDivider = styled(Divider)`

`;

