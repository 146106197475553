import React, { Fragment } from 'react';
import {
  Button,
  Paper,
  TextField,
  Alert,
  Grid,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../reducers/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link } from '../../globalStyle';

const UserLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const excludedPath = '/user/update-password';
  const excludedPathUserAuth = '/user/auth';


  // const { from } = location.state || { from: { pathname: '/' } };
  const { from } = location.state || { from: { pathname: location.pathname } };

  if (from.pathname === excludedPath) {
    from.pathname = '/user/dashboard';
  }
  else if (from.pathname === excludedPathUserAuth) {
    from.pathname = '/';
  }


  const error = useSelector((state) => state.auth.error);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string()
      .min(6, 'Password should be of minimum 6 characters length')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const resultAction = await dispatch(loginUser(values)).unwrap();
        if (resultAction) {
          navigate(from);
        }
      } catch (error) {
        // Handle error if necessary
      } finally {
        setSubmitting(false);
      }
    },
  });

 

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={0} >
          <Grid spacing={2} mt={5} container direction="column" sx={{ px: { xs: 0, sm: 2 }, py: 3 }}>
            <Grid item xs={12} md={6} sx={{ boxSizing: 'border-box' }}>
              <Typography variant="h5" mb={3}>Login</Typography>
              <TextField
                label="Email"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ boxSizing: 'border-box' }}>
              <TextField
                label="Password"
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
              />
            </Grid>
          
            <Grid item xs={12} md={6}>
              <Link to="/password/forgot" color="primary">
                Forget Password ?
              </Link>
            </Grid>
            <Grid item xs={12} md={6} textAlign="right">
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? 'Logging in...' : 'Log In'}
              </Button>

              {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

            </Grid>
          </Grid>
        </Paper>
      </form>
    </Fragment>
  );
}

export default UserLogin;