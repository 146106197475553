import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from 'react-toastify';
import AppURL from '../api/AppURL';

// Async thunk for submitting the quotation
export const submitQuotation = createAsyncThunk(
  'quotation/submitQuotation',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiSubmitQuote(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || 'Unknown error occurred while submitting quotes');
    }
  }
);

const quotationSlice = createSlice({
  name: 'quotation',
  initialState: {
    formData: {
      name: '',
      email: '',
      phone: '',
      customization: '',
      imageUpload: null,
    },
    status: 'idle', // can be 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  },
  reducers: {
    // Optional: Add reducers to handle local state changes if needed
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetFormData: (state) => {
      state.formData = {
        name: '',
        email: '',
        phone: '',
        customization: '',
        imageUpload: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitQuotation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitQuotation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.submittedData = action.payload;
        toast.success('Quotation Request submitted successfully!');
        state.error = null; // clear any previous errors
      })
      .addCase(submitQuotation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        toast.error('Error submitting Quotation Request. Please try again.');
      });
  },
});

export const { setFormData, resetFormData } = quotationSlice.actions;
export default quotationSlice.reducer;
