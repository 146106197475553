import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";

export const subscribeUser = createAsyncThunk(
  'subscriber/subscribeUser',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiSubscribes(), formData);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown subscribeUser  error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

const subscriberSlice = createSlice({
  name: 'subscriber',
  initialState: {
    status: 'idle',
    message: null,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.status = 'idle';
      state.message = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscribeUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(subscribeUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message = action.payload.message;
      })
      .addCase(subscribeUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || 'Failed to subscribe';
      });
  },
});

export const { resetState } = subscriberSlice.actions;

export default subscriberSlice.reducer;