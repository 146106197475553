import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import AppURL from '../api/AppURL';

// Thunk for posting visitor info to the server
export const postVisitorInfo = createAsyncThunk(
  'visitor/postVisitorInfo',
  async (visitorInfo, thunkAPI) => {
    try {
      const response = await axios.post(AppURL.VisitorDetails(), visitorInfo);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const visitorSlice = createSlice({
  name: 'visitor',
  initialState: {
    visitorInfo: null,
    status: 'idle',
    error: null
  },
  reducers: {
    clearVisitorInfo(state) {
      state.visitorInfo = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postVisitorInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postVisitorInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.visitorInfo = action.payload.data;
      })
      .addCase(postVisitorInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export const { clearVisitorInfo } = visitorSlice.actions;

export default visitorSlice.reducer;
