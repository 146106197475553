import React from 'react'
import { AnnouncementBox, AnnouncementWrapper } from './announcement.style'
import { Container } from '../../globalStyle'
import { Box, Typography } from '@mui/material'
import SocialLinks from '../social-links/social-links.component'

const Announcement = () => {
  return (
    <AnnouncementBox>
      <Container>
        <AnnouncementWrapper>
          <Box sx={{ color: 'white' }}>
            <Typography variant='body2'>Welcome New You Sports!</Typography>
          </Box>
          <Box>
            <SocialLinks />
          </Box>
        </AnnouncementWrapper>
      </Container>
    </AnnouncementBox>
  )
}

export default Announcement