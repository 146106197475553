import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../reducers/authSlice';
import { selectCartTotalQuantity } from '../../reducers/cartSlice';
import { Box, Typography, IconButton, Avatar, Badge, Menu, MenuItem, Divider, Tooltip } from '@mui/material';
import {
  Settings,
  Logout,
  ViewList,
  Diversity1,
  Redeem,
  ManageAccounts,
} from '@mui/icons-material';
import { CartIcon, Link } from '../../globalStyle';
import { ButtonMui } from '../button/button.mui';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../confirm-dialog/confirm-dialog.component';

const AccountMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalQuantity = useSelector(selectCartTotalQuantity);
  const userAuth = useSelector((state) => state.auth.user);
  const formattedName = useMemo(() => userAuth?.name?.toUpperCase(), [userAuth]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmLogout = () => {
    dispatch(logout());
    setOpenConfirmDialog(false);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleClose();
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        p: 1,
        
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          xs: { justifyContent: 'space-between' },

        }}
      >
        <Typography sx={{ minWidth: 100, textAlign: 'left' }}>
          <Badge badgeContent={totalQuantity} color="warning">
            <Link to="/cart">
              <CartIcon />
            </Link>
          </Badge>
        </Typography>
        <Typography>

          {formattedName}
        </Typography>

        {/* Login / Register or Avatar with Menu */}
        {!userAuth ? (
          <Link to="/user/auth" color="primary">
            <ButtonMui variant="outlined">Login | Register</ButtonMui>
          </Link>
        ) : (
          <Tooltip title="Account settings">
            <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true">
              <Avatar sx={{ width: 32, height: 32 }}>{formattedName?.charAt(0)}</Avatar>
            </IconButton>
          </Tooltip>
        )}
      </Box>

      {/* Account Menu */}
      {userAuth && (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => handleNavigate('/user/account-details')} sx={{ color: 'primary.main' }}>
            <ManageAccounts color="primary" fontSize="small" sx={{ marginRight: '8px' }} />
            Profile
          </MenuItem>
          <MenuItem onClick={() => handleNavigate('/user/orders')} sx={{ color: 'primary.main' }}>
            <ViewList color="primary" fontSize="small" sx={{ marginRight: '8px' }} />
            My Orders
          </MenuItem>
          <MenuItem onClick={() => handleNavigate('/user/my-teams')} sx={{ color: 'primary.main' }}>
            <Diversity1 color="primary" fontSize="small" sx={{ marginRight: '8px' }} />
            My Teams
          </MenuItem>
          <MenuItem onClick={() => handleNavigate('/user/mockups')} sx={{ color: 'primary.main' }}>
            <Redeem color="primary" fontSize="small" sx={{ marginRight: '8px' }} />
            My Mockups
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleNavigate('/user/dashboard')} sx={{ color: 'primary.main' }}>
            <Settings color="primary" fontSize="small" sx={{ marginRight: '8px' }} />
            Dashboard
          </MenuItem>
          <MenuItem onClick={handleLogout} sx={{ color: 'primary.main' }}>
            <Logout color="primary" fontSize="small" sx={{ marginRight: '8px' }} />
            Logout
          </MenuItem>
        </Menu>
      )}

      {/* Confirm Logout Dialog */}
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmLogout}
        title="Confirm Logout"
        message="Are you sure you want to log out? You will be redirected to the login page."
      />
    </Box>
  );
};

export default AccountMenu;
