import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postVisitorInfo } from '../../reducers/visitorSlice';

const VisitorInfoComponent = () => {
  const { user } = useSelector((state) => state.auth);
  const userId = user ? user.id : null;
  const username = user ? user.name : null;
  const dispatch = useDispatch();
  const pagePath = window.location.pathname;
  const userAgent = navigator.userAgent;

  useEffect(() => {
    // Dispatch the action to post visitor info
    dispatch(postVisitorInfo({ pagePath, userId, username, userAgent }));
  }, [pagePath,dispatch,userId, username, userAgent]);

  return null;

}

export default VisitorInfoComponent 