import { createSelector, createSlice } from "@reduxjs/toolkit";


export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
  },
  reducers: {
    addToCart: (state, action) => {
      const { productId, image, minQty, qtyType, slug, name, type, variations } = action.payload;
      Object.entries(variations).forEach(([variation, values]) => {
        Object.entries(values).forEach(([value, sizes]) => {
          // Filter out sizes with quantity greater than 0
          const filteredSizes = Object.entries(sizes)
            .filter(([size, details]) => details.quantity > 0)
            .reduce((acc, [size, details]) => {
              acc[size] = details;
              return acc;
            }, {});

          if (Object.keys(filteredSizes).length > 0) {
            const itemIndex = state.items.findIndex(item => item.productId === productId && item.type === type && item.variation === variation && item.value === value);
            if (itemIndex !== -1) {
              // Item already exists, update the quantities
              state.items[itemIndex].sizes = {
                ...state.items[itemIndex].sizes,
                ...filteredSizes,
              };
            } else {
              // Item doesn't exist, add it to the cart
              state.items.push({
                productId,
                thumbnail: image,
                minQty,
                qtyType,
                slug,
                name,
                type,
                variation,
                value,
                sizes: filteredSizes,
              });
            }
          }
        });
      });
    },


    removeFromCart: (state, action) => {
      const { productId, type, variation, value, size } = action.payload;
      const findIndex = state.items.findIndex((item) => item.productId === productId && item.type === type && item.variation === variation && item.value === value);
      if (findIndex !== -1) {
        const item = state.items[findIndex];
        if (item.sizes[size]) {
          delete item.sizes[size];

          if (Object.keys(state.items[findIndex].sizes).length === 0) {
            state.items.splice(findIndex, 1); // Remove the item from the array if no sizes left
          }
        }
      }
    },
    updateQuantityDirectly: (state, action) => {
      const { productId, type, variation, value, size, quantity } = action.payload;
      const findIndex = state.items.findIndex(item => item.productId === productId && item.type === type && item.variation === variation && item.value === value);

      if (findIndex !== -1 && state.items[findIndex].sizes[size]) {
        state.items[findIndex].sizes[size].quantity = Math.min(999, Math.max(1, quantity));
      }
    },
    updateQuantity: (state, action) => {
      const { productId, type, variation, value, size } = action.payload;
      const findIndex = state.items.findIndex((item) => item.productId === productId && item.type === type && item.variation === variation && item.value === value);

      if (findIndex !== -1 && state.items[findIndex].sizes[size]) {
        state.items[findIndex].sizes[size].quantity = Math.min(999, Math.max(1, state.items[findIndex].sizes[size].quantity + 1));
      }
    },
    decrementQuantity: (state, action) => {
      const { productId, type, variation, value, size } = action.payload;
      const findIndex = state.items.findIndex((item) => item.productId === productId && item.type === type && item.variation === variation && item.value === value);

      if (findIndex !== -1 && state.items[findIndex].sizes[size]) {
        state.items[findIndex].sizes[size].quantity = Math.max(1, state.items[findIndex].sizes[size].quantity - 1);
      }
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const selectCartTotalQuantity = createSelector(
  [(state => state.cart.items)],
  (items) => items.reduce((total, item) => {
    const totalItem = Object.values(item.sizes).reduce((sum, { quantity }) => sum + (quantity || 0), 0);
    return total + totalItem;
  }, 0)
);

export const selectCartTotalPrice = createSelector(
  [state => state.cart.items],
  (items) => items.reduce((totalCartPrice, item) => {
    const itemTotalPrice = Object.values(item.sizes).reduce((total, { quantity, price }) => {
      return total + ((quantity || 0) * (price || 0));
    }, 0);
    return totalCartPrice + itemTotalPrice;
  }, 0)
);


export const {
  addToCart,
  removeFromCart,
  updateQuantity,
  decrementQuantity,
  updateQuantityDirectly,
  clearCart
} = cartSlice.actions;
export default cartSlice.reducer;