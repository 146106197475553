import { useState } from 'react';

const useLoadMore = (initialCount = 2, step = 2) => {
  const [visibleCount, setVisibleCount] = useState(initialCount);
  const [showingMore, setShowingMore] = useState(true);

  // Function to handle loading more items
  const handleLoadMore = (totalItems) => {
    setVisibleCount(prevCount => {
      const newCount = prevCount + step;
      // Update showingMore based on whether newCount is less than totalItems
      setShowingMore(newCount < totalItems);
      return newCount;
    });
  };

  // Function to handle showing fewer items
  const handleShowLess = (initialCount, totalItems) => {
    setVisibleCount(prevCount => {
      const newCount = Math.max(initialCount, prevCount - step);
      // Update showingMore based on whether newCount is less than totalItems
      setShowingMore(newCount < totalItems);
      return newCount;
    });
  };

  return {
    visibleCount,
    showingMore,
    handleLoadMore,
    handleShowLess
  };
};

export default useLoadMore;
