import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1300; /* Above most Material-UI components */
`;

const Spinner = styled(CircularProgress)`
  
`;

const FullPageSpinner = () => {
  return (
    <SpinnerContainer>
      <Spinner size={60} color='primary' />
    </SpinnerContainer>
  );
};

export default FullPageSpinner;