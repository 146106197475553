import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";

// Define the async thunk
export const submitReview = createAsyncThunk(
  'review/submitReview',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiSubmiteReview(), formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message || 'Unknown error occurred while submitting review');
    }
  }
);

export const fetchProductReviews = createAsyncThunk(
  'review/fetchProductReviews',
  async (productId, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetProductReviews(productId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message || 'Unknown error occurred while fetching reviews');
    }
  }
);


export const submitVote = createAsyncThunk(
  'votes/submitVote',
  async ({ reviewId, voteType }, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiSubmitReviewVote(reviewId), { voteType });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message || error.message || 'Unknown error occurred while submitting vote');
    }
  }
);

const reviewSlice = createSlice({
  name: 'reviews',
  initialState: {
    productReviews: [],
    loading: false,
    error: null,
    status: 'idle',
  },
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitReview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitReview.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(submitReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchProductReviews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductReviews.fulfilled, (state, action) => {
        state.loading = false;
        state.productReviews = action.payload;
      })
      .addCase(fetchProductReviews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(submitVote.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitVote.fulfilled, (state, action) => {
        state.status = 'succeeded';

      })
      .addCase(submitVote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});


export default reviewSlice.reducer;
