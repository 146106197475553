import DOMPurify from "dompurify"

export const renderSanitizedHTML = (html) => {
  return { __html: DOMPurify.sanitize(html) };
}

/**Sanitize before submitting */
export const sanitizeInput = (input) => {
  const sanitized = DOMPurify.sanitize(input, {
    ALLOWED_TAGS: [], // Disallow all tags
    ALLOWED_ATTR: []  // Disallow all attributes
  });
  return sanitized;
}

/** Size chart for teams */
export const sizeDefault = {
  category: '', // e.g., 'adult', 'youth'
  gender: '', // e.g., 'male', 'female'
  type: '', // e.g., 'top', 'bottom'
  size: '', // Size (e.g., 'L', 'M')
  details: {
    chest: '',
    length: '',
    shoulder: '',
    sleeve_length: '',
    sleeve_open: '',
    waist: '',
    inseam: '',
    hip: '',
    head_circumference: '',
  }
};

export const safeParseJSON = (jsonString, defaultValue = {}) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    console.error("Error parsing JSON:", e);
    return defaultValue;
  }
};

export function capitalizeFirstLetter(string = '') {
  return string.replace(/\b(\w)/g, s => s.toUpperCase());
}

export const ProductTitle = (name, slice = 50) => {
  const device = getDeviceType();

  // Handle mobile case with a slice if the name is longer than the slice limit
  if (device === 'mobile') {
    if (name.length > slice) {
      return capitalizeFirstLetter(name.slice(0, slice)) + '...'; // Shorten for mobile
    }
  }

  // Return full name if the device is not mobile or name is short enough
  return capitalizeFirstLetter(name);
};

/**Detech Mobile Device */
export const getDeviceType = () => {
  const userAgent = navigator.userAgent;

  // Check for mobile devices
  if (/Mobi|Android/i.test(userAgent)) {
    return 'mobile';
  }

  // Check for tablets (common identifiers)
  if (/iPad|Android.*(mobile)?|Tablet|Touch/.test(userAgent)) {
    return 'tablet';
  }

  // Default to desktop
  return 'desktop';
};

export const transformSizeData = (sizes) => {
  if (!sizes) return [];
  // Create a map to store sizes by category
  const categoryMap = new Map();

  sizes.forEach(item => {
    const { name, size_category } = item;
    const { id, name: categoryName, slug } = size_category;

    // Initialize category if not exists
    if (!categoryMap.has(id)) {
      categoryMap.set(id, {
        id,
        name: categoryName,
        slug,
        sizes: []
      });
    }

    // Add size to the category
    categoryMap.get(id).sizes.push({ name });
  });

  // Convert the map to an array
  return Array.from(categoryMap.values());
}




export const bannerPages = {
  HOME_PAGE: 'home',
  SHOP_PAGE: 'shoppage',
  PRODUCT_PAGE: 'productpage',  // Correct spelling here
  CART_PAGE: 'cartpage',
}