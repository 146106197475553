import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AppURL from "../api/AppURL";
import axios from "axios";


export const fetchFeedbacks = createAsyncThunk(
  "feedback/fetchFeedback",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetFeedbacks());
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Unknown error occurred while fetching feedback";
      return rejectWithValue(errorMessage);
    }
  }
);

const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    feedbacks: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedbacks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFeedbacks.fulfilled, (state, action) => {
        state.loading = false;
        state.feedbacks = action.payload;
      })
      .addCase(fetchFeedbacks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default feedbackSlice.reducer;

