import React from 'react'
import { StyledLink, StyledList, StyledListItem } from './footer-navigation.style'

const FooterNavigation = () => {
  return (
    <div>
      <StyledList>
        <StyledListItem>
          <StyledLink>Home</StyledLink>
        </StyledListItem>
        <StyledListItem>
          <StyledLink>Shop</StyledLink>
        </StyledListItem>
        <StyledListItem>
          <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
        </StyledListItem>
        <StyledListItem>
          <StyledLink to="/refund-return">Refund and Return</StyledLink>
        </StyledListItem>
        <StyledListItem>
          <StyledLink>Terms and Conditions</StyledLink>
        </StyledListItem>
      </StyledList>
    </div>
  )
}

export default FooterNavigation