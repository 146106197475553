import React, { Fragment, useState, useEffect } from 'react';
import { ScrollIcon, ScrollTopContainer } from './ScrollTop.style.jsx';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


const ScrollTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showTopBtn && (
        <ScrollTopContainer
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <ScrollIcon>
            <ArrowUpwardIcon />
          </ScrollIcon>
        </ScrollTopContainer>
      )}
    </>
  );
};

export default ScrollTop;