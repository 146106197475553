export const maskName = (name) => {
  if (!name || name.length <= 2) return name; // No masking for very short names

  const firstChar = name.charAt(0).toUpperCase();;
  const lastChar = name.charAt(name.length - 1).toUpperCase();;
  const middlePart = '*'.repeat(name.length - 2); // Create asterisks for the middle part
  return `${firstChar}${middlePart}${lastChar}`;
};


export const calculateAverageRating = (product) => {
  if (!product || !Array.isArray(product.reviews)) return 0;

  const allRatings = product.reviews.flatMap(review =>
    review.details.map(detail => {
      const rating = Number(detail.rating);
      return isNaN(rating) ? 0 : rating; // Handle non-numeric ratings
    })
  );

  if (allRatings.length === 0) return 0;

  const average = allRatings.reduce((acc, rating) => acc + rating, 0) / allRatings.length;
  return average;
};
