import React from 'react'
import QuotationForm from '../form/quotation-form'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'

const QuotationDialog = ({ open, handleClose }) => {
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogContent>
        <QuotationForm />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant='outlined'
          style={{ padding: '8px 16px', fontWeight: 'bold' }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default QuotationDialog