// src/features/user/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import AppURL from '../api/AppURL';


export const updateUserName = createAsyncThunk(
  'user/updateName',
  async (name, { rejectWithValue }) => {
    try {
      await axios.post(AppURL.updateUserDetail(), { name });
      return name;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error updating user name';
      return rejectWithValue(errorMessage);
    }

  });

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (_, { rejectWithValue }) => {
    try {
      await axios.delete(AppURL.deleteUser());
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error while deleting user';
      return rejectWithValue(errorMessage);
    }

  });

const userSlice = createSlice({
  name: 'userDetails',
  initialState: {
    name: '',
    email: '',
    createdAt: '',
    status: 'idle',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUserName.fulfilled, (state, action) => {
        state.name = action.payload;
      });
  },
});

export default userSlice.reducer;
