import { Typography } from '@mui/material'
import React from 'react'
import { ContainerBox } from './error-404.style'
import { Link } from '../../globalStyle'
import { ButtonMui } from '../button/button.mui'

const Error404 = ({
  title = "404 - Page not found",
  content = 'The page you are looking for might have been removed had its name changed or is temporarily unavailable.'
}) => {

  return (
    <ContainerBox>
      <Typography variant='h4'>{title}</Typography>
      <Typography variant='body1'>{content}</Typography>
      <Link to="/"><ButtonMui>Back to Home</ButtonMui></Link>
    </ContainerBox>
  )
}

export default Error404