import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import AppURL from '../api/AppURL';

// Async thunk to fetch recent views
export const fetchRelatedProducts = createAsyncThunk(
  'relatedProducts/fetchRelatedProducts',
  async ({ categorySlug, categoryId, productId, type, signal }, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiRelatedProducts(), {
        params: { category_slug: categorySlug, category_id: categoryId, product_id: productId, type },
        signal,
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle the cancelation gracefully (optional)
        return rejectWithValue('Request canceled');
      }
      return rejectWithValue(error.message || error.response.data || 'Unknown error occurred while fetching related products');
    }
  }
);



const relatedProductsSlice = createSlice({
  name: 'relatedProducts',
  initialState: {
    relatedProducts: [],
    status: 'idle',
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRelatedProducts.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(fetchRelatedProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        state.relatedProducts = action.payload;
      })
      .addCase(fetchRelatedProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default relatedProductsSlice.reducer;
