import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";




export const fetchAddresses = createAsyncThunk(
  'addresses/fetchAddresses',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(AppURL.ApiAddresses());
      return response.data.address;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }

);
export const addAddress = createAsyncThunk(
  'addresses/addAddress',
  async (address, thunkAPI) => {
    try {
      const response = await axios.post(AppURL.ApiAddresses(), address);
      return response.data.address;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const fetchShippingAddress = createAsyncThunk(
  'addresses/fetchshippingAddress',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(AppURL.ApiGetShipAddress());
      return response.data.address;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const updateShipToAddress = createAsyncThunk(
  'addresses/updateShipToAddress',
  async ({ addressId, shipTo }, thunkAPI) => {
    try {
      const response = await axios.patch(AppURL.ApiUpdateShipTo(addressId), { ship_to: shipTo });
      return response.data.address;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const setDefaultAddress = createAsyncThunk(
  'addresses/setDefaultAddress',
  async ({ addressId, setDefault }, thunkAPI) => {
    try {
      const response = await axios.patch(AppURL.ApiSetDefaultAddress(addressId), { set_default: setDefault });
      return response.data.address;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const updateAddress = createAsyncThunk(
  'addresses/updateAddress',
  async ({ id, submissionData }, thunkAPI) => {
    try {
      const response = await axios.patch(AppURL.ApiUpdateAddress(id), submissionData);
      return response.data.address;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Address updating error occurred';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const deleteAddress = createAsyncThunk(
  'addresses/deleteAddress',
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(AppURL.ApiDeleteAddress(id));
      return response.data.id;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Address Deleting error occurred';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

const initialState = {
  loading: false,
  items: [],
  error: null,

}
export const addressSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Existing cases for addAddress
      .addCase(fetchAddresses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload || [];
      })
      .addCase(fetchAddresses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Customize based on your error handling strategy
      })
      .addCase(fetchShippingAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShippingAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload || [];
      })
      .addCase(fetchShippingAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle setDefault actions
      .addCase(setDefaultAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setDefaultAddress.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.items.findIndex(address => address.id === action.payload.id);
        if (index !== -1) {
          state.items.forEach(address => {
            address.address_default = 0;
          });
          state.items[index] = {
            ...state.items[index],
            ...action.payload, // Update the address with the response data
            address_default: 1, // Ensure ship_to is set to true for this address
          };
        }

      })
      .addCase(setDefaultAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;;

      })
      // Handle addAddress actions
      .addCase(addAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAddress.fulfilled, (state, action) => {
        state.loading = false;

        state.items = state.items.map(address => ({
          ...address,
          ship_to: 0
        }));

        const newAddress = {
          ...action.payload,
          ship_to: 1
        }
        state.items.push(newAddress);
      })
      .addCase(addAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;;

      })
      .addCase(updateShipToAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateShipToAddress.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.items.findIndex(address => address.id === action.payload.id);
        if (index !== -1) {
          state.items.forEach(address => {
            address.ship_to = 0;
          });
          state.items[index] = {
            ...state.items[index],
            ...action.payload, // Update the address with the response data
            ship_to: 1, // Ensure ship_to is set to true for this address
          };
        }

      })
      .addCase(updateShipToAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.items.findIndex(address => address.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(updateAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        state.loading = false;

      })
      .addCase(deleteAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

  },
});

export default addressSlice.reducer;