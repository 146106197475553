// src/reducers/groupSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AppURL from '../api/AppURL';
import axios from 'axios';

export const fetchGroupsByProductId = createAsyncThunk(
  'groups/fetchGroupsByProductId',
  async ({productId},{  signal, rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetGroupsById(productId), { signal });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle the cancelation gracefully (optional)
        return rejectWithValue('Request canceled');
      }
      const errorMessage = error.response?.data?.message || 'unknown error occurred groups product';
      return rejectWithValue(errorMessage);
    }
  });

const groupSlice = createSlice({
  name: 'groups',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupsByProductId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGroupsByProductId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchGroupsByProductId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default groupSlice.reducer;
