import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";


export const addTeam = createAsyncThunk(
  'teams/addTeam',
  async (teamData, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiTeams(), teamData);
      return response.data.message;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);
export const updateTeam = createAsyncThunk(
  'teams/updateTeam',
  async ({ teamId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiUpdateTeam(teamId), formData);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'error while updating team';
      return rejectWithValue(errorMessage);
    }
  }
);
export const deleteTeam = createAsyncThunk(
  'teams/deleteTeam',
  async (teamId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(AppURL.ApiDeleteTeam(teamId));
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error while deleting team';
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchSelectedTeam = createAsyncThunk(
  'teams/fetchSelectedTeam',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiTeamsSelected());
      return response.data.team;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateSelectedTeam = createAsyncThunk(
  'teams/updateSelectedTeam',
  async ({ teamId, selected }) => {
    try {
      const response = await axios.post(AppURL.ApiUpdateTeamsSelected(), { team_id: teamId, selected });
      return response.data;
    } catch (error) {

    }
  }
);

export const addTeamsPlayer = createAsyncThunk(
  'teams/addTeamPlayer',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiAddTeamsPlayer(), formData);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateTeamPlayer = createAsyncThunk(
  'teams/updateTeamPlayer',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiUpdateTeamsPlayer(), formData);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error while updating Player';
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteTeamPlayer = createAsyncThunk(
  'teams/deleteTeamPlayer',
  async ({ teamId, playerId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(AppURL.ApiDeleteTeamsPlayer(teamId, playerId));
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error while deleting Player';
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchAllTeams = createAsyncThunk(
  'teams/fetchAllTeams',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiTeams());
      return response.data.teams;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

/**Cart and checkout page */
export const fetchTeamById = createAsyncThunk(
  'teams/fetchTeamById',
  async (teamId, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiTeamsById(teamId));
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);
const initialState = {
  loading: false,
  items: [],
  error: null,
  status: '',

}

export const teamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTeams.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllTeams.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
        state.error = null;
      })
      .addCase(fetchAllTeams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { team } = action.payload;
        const teamIndex = state.items.findIndex((t) => t.id === team.id);
        if (teamIndex !== -1) {
          state.items[teamIndex] = { ...state.items[teamIndex], ...team };
        }
      })
      .addCase(updateTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.status = message;
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteTeam.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      })
      .addCase(fetchSelectedTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = 'pending';
      })
      .addCase(fetchSelectedTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload || [];
        state.error = null;
        state.status = 'success';

      })
      .addCase(fetchSelectedTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = 'rejected';
      })
      .addCase(updateSelectedTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSelectedTeam.fulfilled, (state, action) => {
        const { team_id } = action.payload;
        const teamIndex = state.items.findIndex((team) => team.id === team_id);

        if (teamIndex !== -1) {
          // Update all teams to set 'selected' to false
          state.items = state.items.map((team) => ({
            ...team,
            selected: false,
          }));
          // Update the selected team to set 'selected' to true
          state.items[teamIndex] = {
            ...state.items[teamIndex],
            selected: true,
          };
        }

        state.loading = false;
        state.error = null
      })
      .addCase(updateSelectedTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addTeamsPlayer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addTeamsPlayer.fulfilled, (state, action) => {
        state.loading = false;
        const { team_id, player } = action.payload;
        const teamIndex = state.items.findIndex(item => item.id === team_id);
        if (teamIndex !== -1) {
          state.items =
            state.items.map(item => item.id === team_id ? { ...item, players: [...item.players, player] } : item);
        }
      })
      .addCase(addTeamsPlayer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateTeamPlayer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTeamPlayer.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { player, team_id } = action.payload;
        const teamIndex = state.items.findIndex(item => item.id === team_id);
        if (teamIndex !== -1) {
          const playerIndex = state.items[teamIndex].players.findIndex(p => p.id === player.id);
          if (playerIndex !== -1) {
            state.items[teamIndex].players[playerIndex] = { ...state.items[teamIndex].players[playerIndex], ...player };
          }
        }

      })
      .addCase(updateTeamPlayer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteTeamPlayer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTeamPlayer.fulfilled, (state, action) => {
        const { team_id, player_id } = action.payload;
        const teamIndex = state.items.findIndex((team) => team.id === team_id);
        if (teamIndex !== -1) {
          // Find the player index in the team
          const playerIndex = state.items[teamIndex].players.findIndex((player) => player.id === player_id);
          if (playerIndex !== -1) {
            // Create a new array without the deleted player
            const updatedPlayers = [
              ...state.items[teamIndex].players.slice(0, playerIndex),
              ...state.items[teamIndex].players.slice(playerIndex + 1),
            ];

            // Update the state with the new array of players
            state.items[teamIndex] = {
              ...state.items[teamIndex],
              players: updatedPlayers,
            };
          }
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteTeamPlayer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

  }

});

export default teamSlice.reducer;