import { Box } from "@mui/material";
import styled from "styled-components";


export const AnnouncementBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 5px;  
`
export const AnnouncementWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`