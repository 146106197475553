// src/features/popularViews/popularViewsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import AppURL from '../api/AppURL';

// Async thunk to fetch popular views
export const fetchPopularViews = createAsyncThunk(
  'popularViews/fetchPopularViews',
  async (type, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetPopularProducts(), { params: { type } });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error.response.data || 'Unknown error occurred while fetching popular products');
    }
  }
);

// Async thunk to track popular view
export const trackPopularView = createAsyncThunk(
  'popularViews/trackPopularView',
  async ({ userId, productId, type }) => {
    try {
      await axios.post(AppURL.ApiPopularViewTrack(), { user_id: userId, product_id: productId, type });
    } catch (error) {

    }
  }
);

const popularViewsSlice = createSlice({
  name: 'popularViews',
  initialState: {
    popularViews: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPopularViews.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPopularViews.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.popularViews = action.payload;
      })
      .addCase(fetchPopularViews.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default popularViewsSlice.reducer;
