import { createSlice } from "@reduxjs/toolkit";



const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    items: {},
    userId: null,
    addressId: null,
    advancePayment: 25,
    outstandingBalance: 0,
    walletBalance: 0,
    couponCode: '',
    couponDiscount: '',
    cartTotal: 0,
    discountedTotal: 0,
    paymentOption: '',
    customization: {},
  },
  reducers: {
    setToAdvancePayment: (state, action) => {
      state.advancePayment = action.payload;
    },
    setWalletBalance: (state, action) => {
      state.walletBalance = action.payload;
    },
    setoutStandingBalance: (state, action) => {
      state.outstandingBalance = action.payload;
    },
    setCartTotal: (state, action) => {
      state.cartTotal = action.payload;
    },
    setDiscountedTotal: (state, action) => {
      state.discountedTotal = action.payload;
    },
    sendCouponCode: (state, action) => {
      state.couponCode = action.payload;
    },
    setCouponDiscount: (state, action) => {
      state.couponDiscount = action.payload;
    },
    setPaymentOption: (state, action) => {
      state.paymentOption = action.payload;
    },
    setCustomNote: (state, action) => {
      state.customization = action.payload;
    },
    setCheckoutItems: (state, action) => {
      state.items = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setShippingId: (state, action) => {
      state.addressId = action.payload;
    },
  },

});
export const {
  setToAdvancePayment,
  setWalletBalance,
  setoutStandingBalance,
  setCartTotal,
  setDiscountedTotal,
  setCouponDiscount,
  setPaymentOption,
  sendCouponCode,
  setCustomNote,
  setCheckoutItems,
  setUserId,
  setShippingId,
} = checkoutSlice.actions;
export default checkoutSlice.reducer;