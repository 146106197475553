import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchQuery: '',
  filteredCategories: [],
  filteredPriceRange: [0, 1000], // Default range
  filterPriceMin: 0,
  filterPriceMax: 1000,
  sortBy: 'name', // default sorting by name
  order: 'asc',
};

const shopSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setFilteredCategories(state, action) {
      state.filteredCategories = action.payload;
    },
    setFilteredPriceRange(state, action) {
      state.filteredPriceRange = action.payload;
    },
    setMinPrice(state, action) {
      state.filterPriceMin = action.payload;
    },
    setMaxPrice(state, action) {
      state.filterPriceMax = action.payload;
    },
    setSortBy(state, action) {
      state.sortBy = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
    resetFilters() {
      return initialState
    }
  },
});

export const {
  setSearchQuery,
  setFilteredCategories,
  setSortBy,
  setOrder,
  resetFilters,
  setFilteredPriceRange,
  setMinPrice,
  setMaxPrice,
} = shopSlice.actions;
export default shopSlice.reducer;