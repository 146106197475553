import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";



export const fetchBanners = createAsyncThunk(
  'banner/fetchBanners',
  async ({ page, device, position, signal }, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetBanners(), {
        params: { page, device, position },
        signal,
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle the cancelation gracefully (optional)
        return rejectWithValue('Request canceled');
      }
      const errorMessage = error.response?.data?.message || 'Unknown Banner error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchUserBanners = createAsyncThunk(
  'banner/fetchUserBanners',
  async ({ page, device, position, signal }, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiUserBanners(), {
        params: { page, device, position },
        signal,
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle the cancelation gracefully (optional)
        return rejectWithValue('Request canceled');
      }
      const errorMessage = error.response?.data?.message || 'Unknown Fetch User Banner error occurred';
      return rejectWithValue(errorMessage);
    }
  }

);

export const storeBannerLogs = createAsyncThunk(
  'banner/storeBannerLogs',
  async ({ userId, page, mediaId, bannerId, actionType }, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiBannerLogs(),
        { user_id: userId, page, banner_id: bannerId, media_id: mediaId, action_type: actionType }
      );
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown Banner logs occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

const bannerSlice = createSlice({
  name: 'banners',
  initialState: {
    banners: [],
    loading: false,
    status: 'idle',
    error: null,
  },
  reducers: {
    resetBanners: (state) => {
      state.banners = [];
      state.userBanners = [];
      state.loading = false;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.status = 'idle';
        state.loading = true;
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        action.payload.forEach((newBanner) => {
          const { position, device, page } = newBanner;

          // Find if the banner already exists in the state
          const bannerIndex = state.banners.findIndex(banner =>
            banner.page === page && banner.position === position && banner.device === device
          );

          if (bannerIndex !== -1) {
            // If banner exists, update it
            state.banners[bannerIndex] = newBanner;
          } else {
            // If banner is new, add it to the array
            state.banners.push(newBanner);
          }
        });
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchUserBanners.pending, (state) => {
        state.status = 'idle';
        state.loading = true;
      })
      .addCase(fetchUserBanners.fulfilled, (state, action) => {
        state.status = 'succeeded';
        action.payload.forEach((newBanner) => {
          const { position, device, page } = newBanner;

          // Find if the banner already exists in the state
          const bannerIndex = state.banners.findIndex(banner =>
            banner.page === page && banner.position === position && banner.device === device
          );

          if (bannerIndex !== -1) {
            // If banner exists, update it
            state.banners[bannerIndex] = newBanner;
          } else {
            // If banner is new, add it to the array
            state.banners.push(newBanner);
          }
        });
        state.loading = false;
      })
      .addCase(fetchUserBanners.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },

});
export const { resetBanners } = bannerSlice.actions;
export default bannerSlice.reducer;