import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'; // useNavigate replaces useHistory
import { updateEmailVerifiedStatus } from '../../reducers/authSlice'; // Action to update the status
import { toast } from 'react-toastify';
import { Box, Typography } from '@mui/material';

const EmailVerificationSuccess = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  // Helper function to get query parameters from URL
  const getQueryParams = (queryString) => {
    const params = new URLSearchParams(queryString);
    return {
      email_verified_at: params.get('email_verified_at'),
    };
  };

  useEffect(() => {
    const { email_verified_at } = getQueryParams(location.search);

    if (email_verified_at) {
      // Dispatch action to update the email_verified_at in Redux
      dispatch(updateEmailVerifiedStatus(email_verified_at));

      // Show a success toast notification
      toast.success('Your email has been successfully verified!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      // Use navigate instead of history.push
      navigate('/');
    }
  }, [dispatch, location.search, navigate]);

  return (
    <Box
      sx={{
        height: '50vh',
        color: 'green',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Center the content vertically
        alignItems: 'center',
        textAlign: 'center', // Ensure text is centered
        px: 2 // Add padding for better responsiveness
      }}
    >
      <Typography variant='h3' gutterBottom>
        Email Verification Successful
      </Typography>
      <Typography variant="body1">
        You are being redirected...
      </Typography>
    </Box>
  );
};

export default EmailVerificationSuccess;
