import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = () => {
  // const { user } = useSelector((state) => state.auth);
  const { user, token } = useSelector((state) => state.auth);
  const isAuthenticated = !!user && !!token; // Check if user and token exist
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/user/auth" replace state={{ from: location }} />;
  }

  return <Outlet />;
};

export default PrivateRoute;