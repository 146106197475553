import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import AppURL from '../api/AppURL';

export const fetchProductQuestions = createAsyncThunk(
  'questions/fetchProductQuestions',
  async (productId, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetProductQuestions(productId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error.response.data || 'Unknown error occurred while fetching product questions');
    }
  }
);

export const fetchGeneralQuestions = createAsyncThunk(
  'questions/fetchGeneralQuestions',
  async (_, rejectWithValue) => {
    try {
      const response = await axios.get(AppURL.ApiGetGeneralQuestions());
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error.response.data || 'Unknown error occurred while fetching questions');
    }
  }
);

export const submitQuestion = createAsyncThunk(
  'questions/submitQuestion',
  async (questionData, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiPostQuestion(), questionData);
      return response.data;
    } catch (error) {
      let message = 'Failed to submit question.';
      // Check if error.response exists and handle accordingly
      if (error.response) {
        switch (error.response.status) {
          case 401:
            message = 'Unauthorized. Please log in.';
            break;
          case 422:
            //Aggregate validation errors
            const errors = error.response.data.errors;
            const errorsMap = Object.keys(errors).reduce((acc, key) => {
              acc[key] = errors[key][0];
              return acc;
            }, {});
            message = 'Validation error(s): ' + Object.values(errorsMap).join(', ');
            // message = 'Validation error(s)';
            break;
          case 500:
            message = 'Server error: ' + (error.message || 'Internal server error.');
            break;
          default:
            message = 'Unknown error occurred: ' + (error.response.data.message || 'Something went wrong.');
        }
      } else {
        message = 'Network error or server not reachable.';
      }

      return rejectWithValue(message ||
        'Unknown error occurred while posting question');
    }

  }
);

const questionsSlice = createSlice({
  name: 'questions',
  initialState: {
    productQuestions: [],
    generalQuestions: [],
    status: 'idle',
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductQuestions.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchProductQuestions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { product_questions } = action.payload;
        state.productQuestions = product_questions; 
      })
      .addCase(fetchProductQuestions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchGeneralQuestions.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchGeneralQuestions.fulfilled, (state, action) => {
        state.loading = false;
        const { general_questions } = action.payload;
        state.generalQuestions = general_questions;
      })
      .addCase(fetchGeneralQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(submitQuestion.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(submitQuestion.fulfilled, (state, action) => {

        state.status = 'succeeded';
      })
      .addCase(submitQuestion.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default questionsSlice.reducer;
