import { useEffect, useState } from 'react';

const useLazyLoadAPI = (triggerElement, apiFunction, resetCondition) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    // Reset hasLoaded when the resetCondition changes
    if (resetCondition) {
      setHasLoaded(false);
    }
  }, [resetCondition]);
  useEffect(() => {
    const element = triggerElement.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasLoaded) {
          apiFunction(); // Trigger the API call when the element is in view
          setHasLoaded(true); // Ensure it doesn't load again
        }
      },
      { threshold: 0.1 } // You can adjust the threshold to trigger earlier or later
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [triggerElement, apiFunction, hasLoaded]);

  return hasLoaded;
};

export default useLazyLoadAPI;