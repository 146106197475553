import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material'; // Import MUI Box for layout

// Destructure size and rounded for styling purposes, but don't pass them to the DOM element.
const LogoImage = styled.img`
  width: ${({ width }) => width || '30px'};
  height: ${({ height }) => height || '30px'};
  object-fit: contain;
  border-radius: ${({ $rounded }) => ($rounded ? '50%' : '0')}; /* Optionally make logo round */
 
`;

const NewYouLogo = ({ nys, newyou, device, width = "30px", height = "30px",  rounded = false, altText = "NewYouSportsLogo" }) => {
  return (
    <Box className={device} display="flex" alignItems="center">
      {nys && (
        <LogoImage
          src="/img/logo/nys-logo.jpg"
          alt={altText}
          className={device}
          width={width}
          height={height}
          $rounded={rounded} // Control whether the logo is rounded
        />
      )}
      {newyou && (
        <LogoImage
          src="/img/logo/newyou-logo.jpg"
          alt={altText}
          className={device}
          width={width}
          height={height}
          $rounded={rounded}
        />
      )}
    </Box>
  );
};

export default NewYouLogo;
