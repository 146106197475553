// src/hooks/useOneSignal.js
import { useEffect } from 'react';
import OneSignal from 'react-onesignal';

let isOneSignalInitialized = false;
const useOneSignal = (appId) => {
  useEffect(() => {
    // Ensure that OneSignal runs on the client side only
    if (typeof window !== 'undefined' && !isOneSignalInitialized) {
      OneSignal.init({
        appId: appId,
        notifyButton: {
          enable: true,
        },
        // Uncomment the below line if running on localhost during development
        // allowLocalhostAsSecureOrigin: true
      });
      isOneSignalInitialized = true; 
    }
  }, [appId]);
};

export default useOneSignal;
