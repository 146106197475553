import styled from "styled-components";

export const InfoContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 20px;
  margin: 17px 0 50px 0;
  border-radius: 5px;
  border: ${(props) => props.$border}
  `;

export const FlexContainer = styled.div`
display: flex;
align-items: center;
gap: 15px;
margin: 15px 0px;

a {
color: black;
}`