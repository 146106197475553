import React from 'react'
import {
  CardItemBox,
  CardItemDesc,
  CardItemPrice,
  CardItemTitle,
  ImageContainer,
  ProductHeading,
} from './product-widget-card-item.style'
import { Box, Typography } from '@mui/material'
import ProductRating from '../product-rating-component/product-rating.component'
import FormatPrice from '../../helpers/formatPrice'
import { capitalizeFirstLetter } from '../../helpers/globalHelper'
import { Link } from '../../globalStyle'
import { calculateAverageRating } from '../../helpers/reviewHelper'
import ImagePlaceholder from '../image-placeholder/image-placeholder.component'

const ProductWidgetCardItem = ({ product = '', niceName = false }) => {
  const averageRating = calculateAverageRating(product);
  return (
    <>
      {product.nice_name && niceName &&
        <Box style={{ margin: '10px 0' }}>
          <ProductHeading>
            {capitalizeFirstLetter(product.nice_name)}
          </ProductHeading>
        </Box>
      }
      <CardItemBox>
        <Link to={`/single/product/${product?.slug}/${product?.id}`}>
          <ImageContainer>
            <ImagePlaceholder
              src={product?.thumbnail}
              alt={product?.name}
              width="150px"
              height="100px"
              className="widget--image"
              object="contain"
            />
          </ImageContainer>
        </Link>
        <CardItemDesc>
          <Link to={`/single/product/${product?.slug}/${product?.id}`}>
            <CardItemTitle variant="h6">
              {product?.name.length > 50
                ? capitalizeFirstLetter(product?.name.slice(0, 50)) + '...'
                : capitalizeFirstLetter(product?.name)}
            </CardItemTitle>
          </Link>
          <Typography variant='body2'>Min.Order: {product?.min_qty}</Typography>
          <ProductRating value={averageRating} />

          <CardItemPrice>
            <FormatPrice price={product?.price} />
          </CardItemPrice>
        </CardItemDesc>
      </CardItemBox>
    </>
  )
}

export default ProductWidgetCardItem