import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const useAxiosAuthHeader = () => {

  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [token]);
};


export default useAxiosAuthHeader;
