import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import { Container } from '../../globalStyle';
import UserLogin from './user-login.component';
import UserRegister from './user-register.component';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../reducers/authSlice';
import { useNavigate } from 'react-router-dom';
const UserAuth = ({ usingModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, token, status } = useSelector((state) => state.auth);

  useEffect(() => {
    if (token && !user) {
      dispatch(fetchUser(token));
    }
  }, [dispatch, token, user]);

  useEffect(() => {
    if (user && !usingModal) {
      navigate('/'); // Redirect to home or another protected route
    }
  }, [user, navigate, usingModal]);

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (user) {
    return (
      <Container
       
      >
        <Box
          sx={{
            textAlign: 'center',
            p: 2,
            backgroundColor: '#ffffff', // White background
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#333', // Dark text color
              mb: 2,
            }}
          >
            You are logged in.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: '#666', // Gray text color for additional info
            }}
          >
            Welcome back! You can now access all the features.
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <UserLogin />
      </Grid>
      <Grid item xs={12} sm={6}>
        <UserRegister />
      </Grid>
    </Grid>

  )
}

export default UserAuth