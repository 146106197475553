import { Box, Typography } from "@mui/material";
import styled from "styled-components";



export const CardBox = styled(Box)`
 margin: 15px 0;
 display: flex;
 flex-direction: column;
  gap: 15px;
`

export const CardTitle = styled(Typography)`
  font-size: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding-bottom: 1.2rem;
  position: relative;

  &::after {
    content: "";
    width: 80px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary};
  }

`


