import React from 'react';
import { Container, Typography, Link, Box, List, ListItem } from '@mui/material';
import styled from 'styled-components';

const Header = styled.header`
  background: #f5f5f5;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Content = styled(Box)`
  margin-top: 20px;
  line-height: 1.6;
`;

const PrivacyPolicy = () => {
  return (
    <>
      <Header>
        <Typography variant="h4">Privacy Policy</Typography>
      </Header>
      <Container>
        <Content>
          <Typography variant="h6">Last Updated: {new Date().toLocaleDateString()}</Typography>
          <Typography variant="h5" gutterBottom>Introduction</Typography>
          <Typography paragraph>
            Welcome to New You Sports (“we,” “our,” “us”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website newyousports.shop. Please read this policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
          </Typography>
          
          <Typography variant="h5" gutterBottom>Information We Collect</Typography>
          <Typography variant="h6">1. Personal Data</Typography>
          <Typography paragraph>
            When you use our website, we may collect personal data such as your IP address, browser type, operating system, and other usage information. This data helps us understand how users interact with our site and improve their experience.
          </Typography>
          <Typography variant="h6">2. Cookies and Tracking Technologies</Typography>
          <Typography paragraph>
            We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small files that are placed on your device to collect information about your browsing activities. You can manage your cookie preferences through your browser settings.
          </Typography>
          <Typography variant="h6">3. Third-Party Analytics</Typography>
          <Typography paragraph>
            We use third-party analytics services, including Google Analytics and Facebook Pixel, to analyze and track user interactions on our website. These services may use cookies to collect data about your activities. For more information on how these services use your data, please review their privacy policies:
          </Typography>
          <List>
            <ListItem>
              <Link href="https://policies.google.com/privacy" target="_blank">Google Analytics Privacy Policy</Link>
            </ListItem>
            <ListItem>
              <Link href="https://www.facebook.com/about/privacy" target="_blank">Facebook Pixel Privacy Policy</Link>
            </ListItem>
          </List>
          <Typography variant="h6">4. Visitor Tracking</Typography>
          <Typography paragraph>
            We may also collect additional data about your visit to our site, including your geographical location, device information, and user agent. This data helps us understand visitor behavior and improve our services.
          </Typography>
          
          <Typography variant="h5" gutterBottom>How We Use Your Information</Typography>
          <Typography paragraph>
            We use the information we collect to:
          </Typography>
          <List>
            <ListItem>Analyze website usage and performance.</ListItem>
            <ListItem>Improve our website and services.</ListItem>
            <ListItem>Personalize your experience on our site.</ListItem>
            <ListItem>Provide customer support and respond to inquiries.</ListItem>
          </List>

          <Typography variant="h5" gutterBottom>Data Sharing and Disclosure</Typography>
          <Typography paragraph>
            We do not sell or rent your personal data to third parties. We may share your information with:
          </Typography>
          <List>
            <ListItem>Service providers who assist us in operating our website and services.</ListItem>
            <ListItem>Analytics providers to help us understand user behavior and improve our offerings.</ListItem>
          </List>
          <Typography paragraph>
            We may also disclose your information if required by law or in response to legal requests.
          </Typography>

          <Typography variant="h5" gutterBottom>Data Security</Typography>
          <Typography paragraph>
            We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee the absolute security of your data.
          </Typography>

          <Typography variant="h5" gutterBottom>Your Choices</Typography>
          <Typography variant="h6">1. Opt-Out of Cookies</Typography>
          <Typography paragraph>
            You can control cookies through your browser settings. Please note that disabling cookies may affect your ability to use certain features of our website.
          </Typography>
          <Typography variant="h6">2. Access and Correction</Typography>
          <Typography paragraph>
            You have the right to access and correct your personal information. If you wish to exercise these rights, please contact us using the details below.
          </Typography>
          
          <Typography variant="h5" gutterBottom>Changes to This Privacy Policy</Typography>
          <Typography paragraph>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page. You are advised to review this policy periodically for any changes.
          </Typography>

          <Typography variant="h5" gutterBottom>Contact Us</Typography>
          <Typography paragraph>
            If you have any questions or concerns about this Privacy Policy, please contact us at:
            <br />
            New You Sports
            <br />
            info@newyousports.pk
          </Typography>
        </Content>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
