import { createContext, useContext, useState } from "react"


const GlobalState = createContext();
export const LOCAL_STORAGE_ADVANCE_PAYMENT = 'advancePayment';
export const LOCAL_STORAGE_USE_WALLET = 'useWallet';
export const LOCAL_STORAGE_CUSTOMIZATION_NOTE = 'customizationNote';
export const LOCAL_STORAGE_PAYMENT_METHOD = 'paymentMethod';
export const LOCAL_STORAGE_COUPON_CODE = 'couponCode';
export const LOCAL_STORAGE_COUPON_DISCOUNT = 'couponDiscount';
export const LOCAL_STORAGE_COUPON_TYPE = 'couponType';

export const GlobalStateProvider = ({ children }) => {

  /**Coupon Code */
  const [couponCode, setCouponCode] = useState('');
  const [couponValidation, setCouponValidation] = useState('info');
  const [couponAlertMessage, setCouponAlertMessage] = useState('');

  /**Checkout page set advance payment */
  const [advancePayment, setAdvancePayment] = useState(() => {
    const value = localStorage.getItem(LOCAL_STORAGE_ADVANCE_PAYMENT);
    return value ? JSON.parse(value) : 25;
  });

  /**Checkout page use Wallet */
  const [useWallet, setUseWallet] = useState(() => {
    const value = localStorage.getItem(LOCAL_STORAGE_USE_WALLET);
    return value ? JSON.parse(value) : true;
  });

  /**cart page customization notes */
  const [customizationNote, setCustomizationNote] = useState(() => {
    const value = localStorage.getItem(LOCAL_STORAGE_CUSTOMIZATION_NOTE);
    return value ? JSON.parse(value) : '';
  });


  /**Open Sidebar for Mobile View */
  const [isMenuOpen, setIsMenuOpen] = useState(false);



  const value = {
    isMenuOpen,
    setIsMenuOpen,
    couponCode,
    setCouponCode,
    couponValidation,
    setCouponValidation,
    couponAlertMessage,
    setCouponAlertMessage,
    advancePayment,
    setAdvancePayment,
    useWallet,
    setUseWallet,
    customizationNote,
    setCustomizationNote,
  }

  return <GlobalState.Provider value={value}>{children}</GlobalState.Provider>

}

export const useGlobalState = () => {
  return useContext(GlobalState);
}