import styled from "styled-components";
import { IpadTablets, MinTablet600, Mobile } from "../../responsive";

export const HeaderSection = styled.header`
   ${MinTablet600({marginBottom: '10px'})};

`;

export const MainContainer = styled.div`
  ${Mobile({marginBottom: '90px'})};
`;


export const Logo = styled.img`
  max-width: 150px;
  max-height: 60px;
  border-radius: 5px 0 0 0;
`;


export const AccountLinks = styled.div`
    display: flex;
    align-items: center;
    span{
      margin-right: 50px;
    }
    span:last-child {
    margin-right: 0;
    } 

  ${Mobile({ width: "98%" })}
  ${IpadTablets({ width: "98%" })}
`;




