import React, { useState } from 'react';
import { Button, TextField, Box, Paper, Grid, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { registerUser } from '../../reducers/authSlice';
import * as yup from 'yup';
import { useFormik } from 'formik';

const UserRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submissionError, setSubmissionError] = useState('');

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    password: yup.string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
    password_confirmation: yup.string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {

      try {
        await dispatch(registerUser(values));
        navigate('/checkout');
      } catch (error) {
        setSubmissionError(error.response?.data?.message || 'An unexpected error occurred.');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={0}>
          <Grid spacing={2} mt={5} container direction="column" sx={{ px: { xs: 0, sm: 2 }, py: 3 }}>
            <Grid item xs={12} md={6} sx={{ boxSizing: 'border-box' }}>
              <Typography variant="h5" mb={3}>Register Now!</Typography>
              <TextField
                label="Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ boxSizing: 'border-box' }}>
              <TextField
                label="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ boxSizing: 'border-box' }}>
              <TextField
                label="Password"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ boxSizing: 'border-box' }}>
              <TextField
                label="Confirm Password"
                name="password_confirmation"
                type="password"
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                fullWidth
              />
            </Grid>
            {submissionError && (
              <Grid item xs={12} md={6}>
                <Typography color="error" variant="body2">
                  {submissionError}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={6} textAlign="right">
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
                startIcon={formik.isSubmitting && <CircularProgress size={20} />}
              >
                {formik.isSubmitting ? 'Registering...' : 'Register'}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Box>
  );
};

export default UserRegister;
