import React from 'react';
import { Container, Typography, Link, Box } from '@mui/material';
import styled from 'styled-components';

const Header = styled.header`
  background: #f5f5f5;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Content = styled(Box)`
  margin-top: 20px;
  line-height: 1.6;
`;

const RefundReturnPolicy = () => {
  return (
    <>
      <Header>
        <Typography variant="h4">Refund and Return Policy</Typography>
      </Header>
      <Container>
        <Content>
          <Typography variant="h6">Last Updated: {new Date().toLocaleDateString()}</Typography>
          <Typography paragraph>
            Thank you for choosing New You Sports for your custom team wear uniforms. We are committed to providing you with high-quality products and excellent service. Please review our refund and return policy below to understand our process and your rights.
          </Typography>

          <Typography variant="h5" gutterBottom>1. Customization and Approval Process</Typography>

          <Typography variant="h6">Design and Approval:</Typography>
          <Typography paragraph>
            Upon receiving your order, our team will create a custom design for your team wear uniform according to your specifications.
            <br />
            We will provide a digital mockup for your review and approval. It is crucial that you carefully review and approve the design to ensure it meets your expectations.
          </Typography>
          <Typography variant="h6">Test Print:</Typography>
          <Typography paragraph>
            After design approval, we will produce a test print of the uniform for your review.
            <br />
            The test print allows you to assess the design, color, and quality before final production. You will need to approve the test print before we proceed with the full production.
          </Typography>
          <Typography variant="h6">Final Production:</Typography>
          <Typography paragraph>
            Once the test print is approved, we will proceed with the final production of your uniforms.
          </Typography>

          <Typography variant="h5" gutterBottom>2. Refunds</Typography>

          <Typography variant="h6">No Refunds for Custom Products:</Typography>
          <Typography paragraph>
            As all uniforms are customized specifically for your team based on your design choices and approval, we do not offer refunds once the final production has begun.
          </Typography>
          <Typography variant="h6">Refunds for Errors:</Typography>
          <Typography paragraph>
            If there is an error or defect in the final product that was not part of the approved design, please contact us immediately. We will assess the issue and, if it is determined to be our fault, we will provide a replacement or a refund for the defective items.
          </Typography>
          <Typography variant="h6">Order Cancellation:</Typography>
          <Typography paragraph>
            Orders can only be canceled before the design approval process is completed. Once the design is approved and the test print has been produced, cancellations are not possible.
          </Typography>

          <Typography variant="h5" gutterBottom>3. Returns</Typography>

          <Typography variant="h6">Return Policy:</Typography>
          <Typography paragraph>
            Due to the customized nature of our products, we do not accept returns unless the item is defective or there was an error in the customization that was not part of the approved design.
          </Typography>
          <Typography variant="h6">Defective or Incorrect Items:</Typography>
          <Typography paragraph>
            If you receive a defective or incorrect item, please contact us within 7 days of receiving the product. Provide detailed information and any supporting evidence (such as photos) of the issue.
          </Typography>
          <Typography variant="h6">Resolution:</Typography>
          <Typography paragraph>
            We will work with you to resolve the issue, which may include providing a replacement or issuing a refund for the defective items.
          </Typography>

          <Typography variant="h5" gutterBottom>4. Contact Us</Typography>

          <Typography paragraph>
            If you have any questions about our refund and return policy or need to report an issue with your order, please contact our customer service team at:
            <br />
            Email:  <Link href="mailto:info@newyousports.pk">info@newyousports.pk</Link>
            
          </Typography>

          <Typography paragraph>
            We appreciate your understanding and cooperation. Thank you for your business!
          </Typography>
        </Content>
      </Container>
    </>
  );
};

export default RefundReturnPolicy;
