import React, { useEffect } from 'react'
import RefundReturnPolicy from '../component/refund-return-policy/refun-return-policy.component'
import { Box } from '@mui/material';

const RefundReturnPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Box sx={{ marginBottom: '100px' }}>
      <RefundReturnPolicy />
    </Box>
  )
}

export default RefundReturnPolicyPage