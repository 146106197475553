import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";
import { toast } from "react-toastify";

export const createOrder = createAsyncThunk(
  'order/createOrder',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(AppURL.ApiCreateOrder(), data);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown Order Placing error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchAllOrders = createAsyncThunk(
  'order/fetchAllOrders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetAllOrder());
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orderTracking: null,
    order: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        toast.success('Order Created Successfully!');
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(`Error placing order: ${action.payload}`);
      })
      .addCase(fetchAllOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllOrders.fulfilled, (state, action) => {
        const { orders } = action.payload;
        state.order = orders;
        state.status = 'succeeded';
      })
      .addCase(fetchAllOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default orderSlice.reducer;