import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import AppURL from '../api/AppURL';
import { logout } from './authSlice';

// Async thunk to fetch mockup data
export const fetchUserMockups = createAsyncThunk(
  'mockups/fetchUserMockups',
  async (_, { rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();

    // Attach cancel signal to axios request
    signal.addEventListener('abort', () => {
      source.cancel('Fetch user mockups request canceled.');
    });

    try {
      const response = await axios.get(AppURL.ApiUserFetchMockups(), {
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Request was cancelled
        return rejectWithValue('Request canceled');
      } else {
        const errorMessage = error.message || 'Unknown error occurred while fetching mockups';
        return rejectWithValue(errorMessage);
      }
    }
  }
);

export const fetchMockupById = createAsyncThunk(
  'mockups/fetchMockupById',
  async ({ mockupId, signal }, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiFetchMockupById(mockupId), { signal });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle the cancelation gracefully (optional)
        return rejectWithValue('Request canceled');
      }
      const errorMessage = error.response?.data?.message || 'Unknown error occurred while fetching mockup';
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchMockupForCart = createAsyncThunk(
  'mockups/fetchMockupForCart',
  async (mockupId, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiFetchMockupForCart(mockupId));
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred while fetching mockup';
      return rejectWithValue(errorMessage);
    }
  }
);

const mockupSlice = createSlice({
  name: 'mockups',
  initialState: {
    items: [],
    selectedMockup: null,
    status: 'idle',
    loading: false,
    singleStatus: 'idle',
    error: null,
  },
  reducers: {
    resetMockups: (state) => {
      state.items = [];
      state.status = 'idle';
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserMockups.pending, (state) => {
        state.status = 'idle';
        state.loading = true;
      })
      .addCase(fetchUserMockups.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserMockups.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(fetchMockupById.pending, (state) => {
        state.singleStatus = 'idle';
        state.loading = true;
      })
      .addCase(fetchMockupById.fulfilled, (state, action) => {
        state.singleStatus = 'succeeded';
        state.loading = false;
        state.selectedMockup = action.payload;
      })
      .addCase(fetchMockupById.rejected, (state, action) => {
        state.singleStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(logout, (state) => {
        state.items = [];
        state.status = 'idle';
        state.loading = false;
        state.error = null;
      });

  },
});
export const { resetMockups } = mockupSlice.actions;
export default mockupSlice.reducer;
