import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import AppURL from '../api/AppURL';

// Async thunk to fetch recent views
export const fetchFeaturedProducts = createAsyncThunk(
  'fetchFeatured/fetchFeaturedProducts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetFeaturedProducts());
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error.response.data || 'Unknown error occurred while fetching featured products');
    }
  }
);


// Function to invalidate and refetch categories
export const invalidateFeatured= () => (dispatch) => {
  // Clear the Featured Products
  dispatch(clearedFeaturedProducts());

  // Refetch the categories
  dispatch(fetchFeaturedProducts());
};

const featuredProductSlice = createSlice({
  name: 'featuredProducts',
  initialState: {
    featuredProducts: [],
    status: 'idle',
    loading: false,
    error: null
  },
  reducers: {
    clearedFeaturedProducts: (state) => {
      state.featuredProducts = []; // Clear categories
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeaturedProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFeaturedProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.featuredProducts = action.payload;
      })
      .addCase(fetchFeaturedProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

// Export the clearedFeaturedProducts action to be used in invalidateFeatured
export const { clearedFeaturedProducts } = featuredProductSlice.actions;
export default featuredProductSlice.reducer;
