import React from 'react'
import Error404 from '../component/error-404-component/error-404.component'
import { Box } from '@mui/material'

const Page404 = () => {
  return (
    <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
      <Error404 title='404 - Page Not Found' />
    </Box>
  )
}

export default Page404