import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from "@mui/material";

// Reusable ImagePlaceholder component
const ImagePlaceholder = ({ src, alt, placeholder, width, height, className, style, object }) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <div style={{ position: 'relative', width, height, ...style }}>
      {/* Placeholder (e.g., Skeleton or placeholder image) */}
      {!loaded && (
        <Skeleton
          variant="rectangular"
          width={width}
          height={height}
          animation="wave"
          style={{ position: 'absolute', top: 0, left: 0, width, height }}
        />
      )}
      {/* Actual Image */}
      <img
        src={src}
        alt={alt}
        className={className}
        style={{ 
          display: loaded ? 'block' : 'none', 
          width: '100%', 
          height: '100%', 
          objectFit: object ? object : 'cover', // This ensures the image fills the space while maintaining aspect ratio
          ...style 
        }}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

ImagePlaceholder.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object, // Added for additional custom styling
};

ImagePlaceholder.defaultProps = {
  alt: 'image',
  placeholder: 'https://via.placeholder.com/1000x250?text=Loading...', // Default placeholder image
  width: '100%',
  height: 'auto',
  className: '',
  style: {},
};

export default ImagePlaceholder;
