import React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ProductRating = ({ value, readOnly = true, onChange = null, label = '', size = 'small', labelVariant = 'caption' }) => {
  return (
    <Box
      sx={{
        '& > legend': { mt: 2 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      }}
    >
      {label && <Typography variant={labelVariant} component="legend">{label}</Typography>}
      <Rating
        name="simple-controlled"
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        size={size}
      />
    </Box>
  )
}

export default ProductRating