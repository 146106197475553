import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AppURL from "../api/AppURL";
import axios from "axios";

export const fetchNewArrivals = createAsyncThunk(
  'newArrivals/fetchNewArrivals',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetNewArrivalsProduct());
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error.response.data || 'Unknown error occurred while fetching new arrivals products');
    }
  }
);

const popularViewsSlice = createSlice({
  name: 'newArrivals',
  initialState: {
    newArrivals: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewArrivals.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNewArrivals.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.newArrivals = action.payload;
      })
      .addCase(fetchNewArrivals.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default popularViewsSlice.reducer;