import React, { useState } from 'react'
import { Box, Typography } from '@mui/material';
import { LoginBox } from './login-required.style'
import { ButtonMui } from '../button/button.mui';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import UserAuthModal from '../user-authentication/user-auth-modal';

const LoginRequired = ({ title = '' }) => {

  const [isUserDialogOpen, setUserDialogOpen] = useState(false);
  const openUserDialog = () => setUserDialogOpen(true);
  const closeUserDialog = () => setUserDialogOpen(false);
  return (
    <LoginBox>
      <Box>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Typography>
        <ButtonMui onClick={openUserDialog} variant="outlined" startIcon={<HowToRegIcon />}>
          Login | Register now
        </ButtonMui>
      </Typography>
      <UserAuthModal
        usingModal={true}
        isDialogOpen={isUserDialogOpen}
        closeDialog={closeUserDialog}
      />
    </LoginBox>
  )
}

export default LoginRequired