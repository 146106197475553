import axios from "axios";

const axiosAuthMiddleware = store => next => action => {
  // Listen for loginUser fulfilled action to set the Authorization header
  if (action.type === 'auth/loginUser/fulfilled') {
    // const token = action.payload.token;
    axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.token}`;
  }

  // Handle logout
  if (action.type === 'auth/logout') {
    delete axios.defaults.headers.common['Authorization'];
  }

  return next(action);
}


export default axiosAuthMiddleware;