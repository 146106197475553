import React from 'react'
import { Heading } from './heading-title.style'

const HeadingTitle = ({ title = "", variant = "h6" }) => {
  return (
    <Heading variant={variant}>
      {title}
    </Heading>
  )
}

export default HeadingTitle