import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";


export const fetchMockupBanners = createAsyncThunk(
  'banner/fetchMockupBanners',
  async ({ page, device, position }, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetBanners(), {
        params: { page, device, position }
      });
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown mockup Banner error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchUserMockupBanners = createAsyncThunk(
  'banner/fetchUserMockupBanners',
  async ({ page, device, position }, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiUserBanners(), {
        params: { page, device, position }
      });
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown mockup banner error occurred';
      return rejectWithValue(errorMessage);
    }
  }

);



const mockupBannerSlice = createSlice({
  name: 'mockupBanners',
  initialState: {
    mockupBanners: [],
    loading: false,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMockupBanners.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMockupBanners.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mockupBanners = action.payload;
      })
      .addCase(fetchMockupBanners.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchUserMockupBanners.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserMockupBanners.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mockupBanners = action.payload;
      })
      .addCase(fetchUserMockupBanners.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },

});
export default mockupBannerSlice.reducer;