// src/features/recentViews/recentViewsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import AppURL from '../api/AppURL';

// Async thunk to fetch recent views
export const fetchRecentViews = createAsyncThunk(
  'recentViews/fetchRecentViews',
  async ({type, userId}, {rejectWithValue}) => {
    try {
      const response = await axios.get(AppURL.ApiGetRecentViewProducts(), { params: { type, userId } });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error.response.data || 'Unknown error occurred while fetching recent products');
    }
  }
);

// Async thunk to track recent view
export const trackRecentView = createAsyncThunk(
  'recentViews/trackRecentView',
  async ({ userId, productId, type }, {rejectWithValue}) => {
    try {
      await axios.post(AppURL.ApiRecentViewTrack(), { user_id: userId, product_id: productId, type });
    } catch (error) {
      return rejectWithValue(error.message || error.response.data || 'Unknown error occurred while storing recent products');
    }
  }
);

const recentViewsSlice = createSlice({
  name: 'recentViews',
  initialState: {
    recentViews: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentViews.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRecentViews.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.recentViews = action.payload;
      })
      .addCase(fetchRecentViews.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default recentViewsSlice.reducer;
