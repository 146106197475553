import styled from "styled-components";
import { Container } from "../../globalStyle";


export const HeaderMobileSection = styled.section`
  
`;

export const HeaderMobileContainer = styled(Container)`
    background-color: ${({ theme }) => theme.colors.white};
    height: 100%;
    width: 100%;
    padding: 20px 10px 0 10px;
    line-height: 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
`;

export const Head = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.white};
`;

export const HeaderBody = styled.div`
  
`;

export const HeaderFooter = styled.div`

`;



export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 15px 0px;
 
 a {
  color: black;
 }
`;

export const SocialContainer = styled.div``;

export const CopyRightContainer = styled.div`
  margin-top: 30px;
  font-size: 14px;
  font-weight: 200;
`;