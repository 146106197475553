import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";

const initialState = {
  status: 'idle',
  products: [],
  singleProduct: {},
  loading: false,
  error: null,
};


export const fetchShopProducts = createAsyncThunk(
  'products/fetchShopProducts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiFetchShopProducts());
      return response.data;
    } catch (err) {
      // This ensures you can access error.response.data in the extraReducers
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProductsForCart = createAsyncThunk(
  'products/fetchProductForCart',
  async (productId, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetProductsForCart(productId));
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown error occurred while fetching product';
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchProductById = createAsyncThunk(
  'products/fetchProductById',
  async ({ productId, signal }, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetProductById(productId), { signal });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle the cancelation gracefully (optional)
        return rejectWithValue('Request canceled');
      }
      const errorMessage = error.response?.data?.message || 'Unknown error occurred while fetching product';
      return rejectWithValue(errorMessage);
    }
  }
);


const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopProducts.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShopProducts.fulfilled, (state, action) => {
        state.status = 'success';
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(fetchShopProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchProductById.pending, (state) => {
        state.status = 'pending';
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.status = 'success';
        state.loading = false;
        state.singleProduct = action.payload;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default productsSlice.reducer;