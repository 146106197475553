import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Link } from '../../globalStyle';
import { Alert } from '@mui/material';
import { sendVerificationEmail } from '../../reducers/emailVerificationSlice';
import ToasterComponent from '../toaster-component/toaster.component';
import { toast } from 'react-toastify';

const EmailSendNotification = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.emailVerification);

  const userEmailVarified = user?.email_verified_at;

  const handleLinkSend = async () => {
    if (!loading) {
      const response = await dispatch(sendVerificationEmail());

      if (response.meta.requestStatus === 'fulfilled') {
        toast.success('Verification link sent successfully!', {
          position: toast.POSITION.BOTTOM_CENTER, // Adjusted position
        });
      } else {
        toast.error(error || 'Failed to resend activation link.', {
          position: toast.POSITION.BOTTOM_CENTER, // Adjusted position
        });
      }
    }
  };

  if (!userEmailVarified && user) {
    return (
      <Container>
        <Alert severity="warning">
          Your email isn't verified yet. Please verify your email.
          <Link
            style={{
              fontWeight: 'bold',
              marginLeft: 4,
              cursor: loading ? 'not-allowed' : 'pointer',
              opacity: loading ? 0.5 : 1,

            }}
            onClick={handleLinkSend}
            color='primary'

          >
            {loading ? 'Sending...' : 'Resend Activation Link'}
          </Link>
        </Alert>
        <ToasterComponent />
      </Container>
    );
  }

  return null;
};

export default EmailSendNotification;
