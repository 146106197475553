import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";

export const fetchSliders = createAsyncThunk(
  'sliders/fetchSliders',
  async ({device }, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiGetSlider(), {
        params: { device }
      });
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unknown slider error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

const sliderSlice = createSlice({
  name: 'sliders',
  initialState: {
    sliders: [],
    loading: false,
    status: 'idle',
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSliders.pending, (state) => {
        state.status = 'idle';
        state.loading = true;
      })
      .addCase(fetchSliders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        state.sliders = action.payload;
      })
      .addCase(fetchSliders.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.payload || action.error.message;
      });

  },

});

export default sliderSlice.reducer;