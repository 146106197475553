import { Box } from "@mui/material";
import styled from "styled-components";



// Container that wraps the scroll-to-top button
export const ScrollTopContainer = styled(Box)`
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
`;

// Styled icon container
export const ScrollIcon = styled('div')`
  background-color: ${({ theme }) => theme.colors.primary};
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &:hover {
    animation: none;
    background: #fff;
    color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;
